import '@emotion/react';

import {readableColor, rgba} from 'polished';
import {CreateStyled} from '@emotion/styled';
import styled from '@emotion/styled/macro';

type ThemeBase = typeof defaultTheme;

declare module '@emotion/react' {
  export interface Theme extends ThemeBase {}
}

const colors = {
  red3: '#E04761',
  red2: '#DA1F3F',
  red1: '#B31A34',
  red4: '#AA0000',
  red5: '#9F3A38',
  blue1: '#0A2233',
  blue2: '#0D3047',
  blue3: '#11153B',
  blue4: '#3E6A89',
  blue5: '#94ACBD',
  blue6: '#3B81F3',
  white1: '#FFFFFF',
  white2: '#F5F5F5',
  grey1: '#979C9E',
  grey2: '#CBCFD1',
  black: '#1B1C1D',
  transparent: 'rgba(0,0,0,0)',
};

export const payStarColors = {
  ...colors,
  primary: {
    red: colors.red2,
    blue: colors.blue3,
    lightBlue: colors.blue6,
    grey: '#B8BEC0',
  },
  basicBoxShadow: 'rgba(34, 36, 38, 0.45)',
  textLabel: '#444',
  transparentHover: '#efefef',

  warning: '#712928',
  warningBackground: '#fff6f6',
  warningBoxShadow: colors.red5,
  warningText: '#7b341e',

  mutedText: colors.grey1,
  errorText: colors.red5,

  moneyPositive: 'green',
  moneyNegative: colors.red5,
};

export const defaultTheme = {
  logoUrl: '',
  primaryColor: payStarColors.primary.blue,
  primaryButtonBg: payStarColors.primary.blue,
  secondaryButtonBg: '#e8e8e8',
  secondaryButtonShadow: rgba(payStarColors.primary.blue, 0.4),
  secondaryButtonText: readableColor(payStarColors.white1),
};

export const generateThemeColors = (primaryColor: string) => ({
  primaryButtonBg: primaryColor,
  secondaryButtonBg: rgba(primaryColor, 0.5),
  secondaryButtonShadow: rgba(primaryColor, 0.35),
  secondaryButtonText: readableColor(rgba(primaryColor, 0.3)),
});

export default styled as CreateStyled;
