import {cx} from '@emotion/css';
import {css} from '@emotion/react/macro';
import React from 'react';
import {Segment, SegmentProps} from 'semantic-ui-react';

type FormContainer = SegmentProps & {
  children: React.ReactNode;
  className?: string;
  padded?: boolean;
  basic?: boolean;
};

export const FormContainer: React.FC<FormContainer> = ({
  children,
  className,
  padded = true,
  basic = false,
  ...props
}) => (
  <Segment
    css={styles}
    className={cx(className, {
      padded,
      basic,
    })}
    {...props}
  >
    {children}
  </Segment>
);

const styles = css`
  &.ui.segment {
    padding: 0;

    &.padded {
      padding: 2em;

      @media only screen and (max-width: 767px) {
        padding: 1em;
      }
    }

    .basic {
      border: 0;
    }

    .quick-pay-portal-content & {
      width: 100%;
      display: inline-block;
    }
  }
`;
