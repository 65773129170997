import {first} from 'lodash';
import React from 'react';
import {useAsync} from 'react-use';
import {Redirect, RouteComponentProps} from '@reach/router';
import {AccountsService} from '../api/generated/AccountsService';
import {CustomersService} from '../api/generated/CustomersService';
import {authLogger, useUser} from '../auth/use-auth';
import {AsyncStateContainer} from '../components/async-state-container';
import {buildPath, routes} from '../routes';

export const CustomerResolver: React.FC<RouteComponentProps> = () => {
  const user = useUser();

  if (user.role !== 'Customer') {
    authLogger.error('Missing customer role', user);
    throw new Error('Cant be here...');
  }

  const fetchAccounts = useAsync(async () => {
    const {data} = await AccountsService.getMyAccounts();
    return data;
  });

  const fetchSlug = useAsync(async () => {
    const {data} = await CustomersService.getCustomerSlug();
    return data;
  });

  const accounts = fetchAccounts.value || [];
  const account = first(accounts);
  const slug = account ? account.businessUnitSlug : fetchSlug.value;

  if (slug) {
    return (
      <Redirect
        noThrow
        to={buildPath(routes.customer.dashboard, {
          slug: slug,
        })}
      />
    );
  }

  return <AsyncStateContainer {...fetchAccounts}>...</AsyncStateContainer>;
};
