/* eslint-disable */

import {
  AdvancedQueryColumnFilter,
  AdvancedQueryColumnSort,
  BusinessUnitSummaryDto,
  Address,
  CreateBusinessUnitRequest,
  BusinessUnitGetDto,
  BusinessUnitHeaderDto,
  BusinessUnitDetailDto,
  BusinessUnitFeatures,
  BusinessUnitTermsOfServices,
  UpdateBusinessUnitRequest,
  EmptyResponse,
  Error,
  BusinessUnitContextDto,
  PaymentChannelSummaryDto,
  PaymentChannelSourceSummaryDto,
  MerchantAccountChannelSummaryDto,
  AcceptedCardBrands,
  TokenizerConfig,
  AccountFieldGetDto,
  PaymentFieldDetailDto,
  MessageDetailDto,
  BusinessUnitClientManagementSettingsDto,
  UpdateBusinessUnitClientManagementSettingsRequest,
  BusinessUnitClientSummaryDto,
  PaymentChannelClientSummaryDto,
  PaymentChannelSourceClientSummaryDto,
  MerchantAccountClientSummaryDto,
  TimeSpan,
  UpdateBusinessUnitFeaturesRequest,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class BusinessUnitsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<BusinessUnitSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrgId(
    params: {
      /**  */
      organizationId: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<BusinessUnitSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/organizations/{organizationId}/business-units';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      organizationId: number;
      /**  */
      body?: CreateBusinessUnitRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/organizations/{organizationId}/business-units';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHeaderById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitHeaderDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/header';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateBusinessUnitRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContextById(
    params: {
      /**  */
      id: number;
      /**  */
      channelType: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitContextDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/context/{channelType}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{channelType}', params['channelType'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingsById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitClientManagementSettingsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/settings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSettings(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateBusinessUnitClientManagementSettingsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitClientManagementSettingsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/settings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContextBySlug(
    params: {
      /**  */
      slug: string;
      /**  */
      channelType: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitContextDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/by-slug/{slug}/context/{channelType}';
      url = url.replace('{slug}', params['slug'] + '');
      url = url.replace('{channelType}', params['channelType'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitClientSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/summary';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateFeatures(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateBusinessUnitFeaturesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BusinessUnitFeatures>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{id}/features';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
