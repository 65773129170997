import React from 'react';
import {useContext} from 'react';
import {TimezoneContext} from '../paystar-portal/time-zone-context-provider';
import {getPooledMoment} from '../utils/get-pooled-moment';

type DateProps = {
  date: number | Date | string;
  format?: string;
};

type TimeProps = {
  time: number | Date | string;
  format?: string;
};

export const DateTimeFormats = {
  Date: 'MM/DD/YY',
  Time: 'hh:mm A',
  DateTime: 'MM/DD/YY hh:mm A',
};

export const DateFormat = React.memo<DateProps>(({date, format = DateTimeFormats.Date}) => {
  const {showTimezone, userTimezoneAbbr} = useContext(TimezoneContext);
  const momentDate = getPooledMoment(date);

  return momentDate.isValid() ? (
    <>
      {momentDate.format(format)}
      {showTimezone && ` (${userTimezoneAbbr})`}
    </>
  ) : null;
});

export const TimeFormat = React.memo<TimeProps>(({time, format = DateTimeFormats.Time}) => {
  const momentDate = getPooledMoment(time, 'LT');
  return momentDate.isValid() ? <>{momentDate.format(format)}</> : null;
});
