import currency from 'currency.js';

const defaultResult = currency('0');
export const safeCurrency = (input) => {
  const result = currency(input);
  return isNaN(result.intValue) ? defaultResult : result;
};

export const formatCurrency = (inputAsMinor: number) => safeCurrency(inputAsMinor / 100).toString();

export const parseCurrency = (inputAsMajor: string) => safeCurrency(inputAsMajor).intValue;
