import {useSelector} from 'react-redux';
import {StoreState, BusinessUnitContext} from './store';

export function useGlobalState<T>(cb: (state: StoreState) => T) {
  return useSelector((state: StoreState) => cb(state));
}

export const useBusinessUnitContext = () =>
  useGlobalState((state) => state.global.context.value as BusinessUnitContext);

export const useBusinessUnits = () => useGlobalState((state) => state.global.businessUnits);
