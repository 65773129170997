export const sessionStore = {
  set: (key: string, value: string) => sessionStorage.setItem(key, value),
  get: (key: string) => sessionStorage.getItem(key),
  getAndRemove: (key: string) => {
    const value = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    return value;
  },
};
