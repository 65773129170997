import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAsyncRetry} from 'react-use';
import {Container, Header, Message} from 'semantic-ui-react';
import {css} from '@emotion/react/macro';
import {Link, RouteComponentProps, useLocation, useNavigate, useParams} from '@reach/router';
import {
  AccountDetailDto,
  DependentFormResponseDto,
  Error,
  PaymentIntentDetailDto,
  Response,
  SubmitFormResponse,
} from '../api/generated/index.defs';
import {FormsService} from '../api/generated/FormsService';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';
import {payStarColors} from '../styles/styled';
import {lighten} from 'polished';
import {Media} from '../styles/breakpoints';
import {User} from '../types';
import {routes} from '../routes';
import {PaymentIntentsService} from '../api/generated/PaymentIntentsService';
import {sessionStore} from '../utils/session-store';
import moment from 'moment';
import {DateTimeFormats} from '../components/date';
import {isInclusivelyAfterDay, isInclusivelyBeforeDay} from 'react-dates';

const DEFAULT_ON_FORM_COMPLETE_URL = routes.customer.dashboard;

export type CustomFormProps = RouteComponentProps & {
  account?: AccountDetailDto;
  businessUnitId: number;
  user?: User;
  formId?: number;
  paymentSessionSessionIdentifier?: string;
  onCompleteUrl?: string;
  onComplete?: () => void;
  onCancel?: () => void;
};

const mapContextToFields = (schemaJson, key, context, initialObject = {}) => {
  const schema = JSON.parse(schemaJson ?? '{}');
  const fields = schema[key] ?? [];

  const result = {
    ...initialObject,
    ...fields.reduce(
      (data, curr) => ({
        ...data,
        [`${curr.fieldName}`]: context[curr.mapFromContextKey] ?? curr.defaultValue,
      }),
      {}
    ),
  };

  return result;
};

const weekends = [0, 6];

const filterFormConfigurationProps = (providedProps) => {
  const {mapFromContextKey, ...propsToApply} = providedProps;
  return propsToApply;
};

export const buildIsOutsideRangeForDatePicker = (
  minimumDayOffset,
  maximumDayOffset,
  skipWeekends
) => {
  if (minimumDayOffset !== undefined) minimumDayOffset--;

  if (maximumDayOffset !== undefined) maximumDayOffset++;

  if (skipWeekends && moment().day() - minimumDayOffset <= 0) {
    minimumDayOffset -= 2;
  }

  if (skipWeekends && moment().day() + maximumDayOffset >= 6) {
    maximumDayOffset += 2;
  }

  const minimumDayCheck =
    minimumDayOffset !== undefined
      ? (x) => isInclusivelyBeforeDay(x, moment().add(minimumDayOffset, 'days'))
      : () => false;

  const maximumDayCheck =
    maximumDayOffset !== undefined
      ? (x) => isInclusivelyAfterDay(x, moment().add(maximumDayOffset, 'days'))
      : () => false;

  var weekendCheck = skipWeekends ? (x) => weekends.some((y) => x.day() === y) : () => false;

  return (x) => minimumDayCheck(x) || maximumDayCheck(x) || weekendCheck(x);
};

const fields = {
  input: (propsToApply) => (
    <Form.Input key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  'input-currency': (propsToApply) => (
    <Form.InputCurrency
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  'input-masked': (propsToApply) => (
    <Form.InputMasked
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  'input-decimal': (propsToApply) => (
    <Form.InputDecimal
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  datepicker: (propsToApply) => {
    const {minimumDayOffset, maximumDayOffset, skipWeekends, ...remainingPropsToApply} =
      propsToApply;

    return (
      <Form.DatePicker
        key={propsToApply.fieldName}
        isOutsideRange={buildIsOutsideRangeForDatePicker(
          minimumDayOffset,
          maximumDayOffset,
          skipWeekends
        )}
        {...filterFormConfigurationProps(remainingPropsToApply)}
      />
    );
  },
  dropdown: (propsToApply) => (
    <Form.Dropdown key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  radio: (propsToApply) => (
    <Form.RadioGroup key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  textarea: (propsToApply) => (
    <Form.TextArea key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  checkbox: (propsToApply) => (
    <Form.Checkbox key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  header: (propsToApply) => {
    const {fieldName, mapFromContextKey, ...remainingPropsToApply} = propsToApply;
    return <Header key={fieldName} {...filterFormConfigurationProps(remainingPropsToApply)} />;
  },
  message: (propsToApply) => {
    const {fieldName, mapFromContextKey, ...remainingPropsToApply} = propsToApply;
    return <Message key={fieldName} {...filterFormConfigurationProps(remainingPropsToApply)} />;
  },
};

export const CustomForm: React.FC<CustomFormProps> = ({
  account,
  businessUnitId,
  formId,
  user,
  onCompleteUrl,
  onComplete,
  onCancel,
  paymentSessionSessionIdentifier,
}) => {
  const navigate = useNavigate();
  const {baseFormId} = useParams<any>();
  const location = useLocation();

  const isDependentForm = !!baseFormId;

  const [submitResults, setSubmitResults] = useState<{
    info?: boolean;
    error?: boolean;
    content?: string;
  }>({});

  const [dependentFormState, setDependentFormState] = useState<DependentFormResponseDto[]>([]);

  const fetchFormConfiguration = useAsyncRetry(async () => {
    if (!formId) return null;

    var request = !user
      ? FormsService.getAnonymousFormConfigurationById
      : FormsService.getFormConfigurationById;

    const {data} = await request({
      businessUnitId: businessUnitId,
      formConfigurationId: formId,
    });
    return data;
  });

  useEffect(() => {
    setDependentFormState(
      JSON.parse(sessionStore.getAndRemove(`formConfiguration:${formId}`) ?? '[]')
    );
  }, [formId]);

  const contextData = useMemo(() => {
    const parsedMeta = JSON.parse(account?.meta ?? '{}');
    const accountMeta = Object.keys(parsedMeta).reduce(
      (obj, curr) => ({...obj, [`meta_${curr}`]: parsedMeta[curr]}),
      {}
    );

    const data = {
      ...accountMeta,
      ...account,
      ...user,
      date: moment().format(DateTimeFormats.DateTime),
    };

    return data;
  }, [account, user]);

  const getFormFields = useMemo(() => {
    if (!fetchFormConfiguration.value) return [];
    const schema: any = JSON.parse(fetchFormConfiguration?.value?.fieldsJson ?? '{}');

    return (
      schema.fields?.reduce((acc: JSX.Element[], curr) => {
        const {fieldType, fieldMatches, ...propsToApply} = curr;
        return [...acc, fields[fieldType](propsToApply) ?? null];
      }, []) ?? []
    );
  }, [fetchFormConfiguration.value]);

  const navigateToDependentForm = useCallback(
    (dependentFormConfigurationId, values) => {
      sessionStore.set(`formConfigurationState:${formId}`, JSON.stringify(values));
      navigate(`${location.pathname}/${dependentFormConfigurationId}${location.search}`);
    },
    [location.pathname, location.search, navigate, formId]
  );

  const getDependentForms = (values) => {
    if (!fetchFormConfiguration.value) return [];
    const schema: any = JSON.parse(fetchFormConfiguration?.value?.fieldsJson ?? '{}');

    if (!schema.dependentForms || schema.dependentForms?.length === 0) return [];

    let remainingDependentForms = false;

    let dependentForms =
      schema.dependentForms?.reduce((acc: JSX.Element[], curr) => {
        const {fieldType, fieldMatches, ...propsToApply} = curr;
        const {
          fieldName,
          content: formName,
          dependentFormConfigurationId,
          ...remainingPropsToApply
        } = propsToApply;
        const matchingState =
          !!dependentFormConfigurationId &&
          dependentFormState?.some(
            (x) => `${x?.formConfigurationId}` === dependentFormConfigurationId
          );

        remainingDependentForms = remainingDependentForms || !matchingState;

        return [
          ...acc,
          matchingState ? (
            <Message key={fieldName}>Completed additional form: {formName}</Message>
          ) : (
            <Form.Button
              key={fieldName}
              type="button"
              disabled={!!matchingState}
              onClick={() => navigateToDependentForm(dependentFormConfigurationId, values)}
              content={formName}
              {...remainingPropsToApply}
            />
          ),
        ];
      }, []) ?? [];

    if (remainingDependentForms) {
      dependentForms = [
        <Header
          className="field"
          key="dependentFormsHeader"
          content="Please complete the following additional forms:"
        />,
        ...dependentForms,
      ];
    }

    return <div className="dependent-forms-container">{dependentForms}</div>;
  };

  const onSubmit = async (values) => {
    if (!formId || !fetchFormConfiguration?.value) return null;

    const valuesToSubmit = mapContextToFields(
      fetchFormConfiguration?.value?.fieldsJson,
      'hiddenFields',
      contextData,
      values
    );

    const validationResult = validateForm(
      fetchFormConfiguration.value.fieldsJson,
      valuesToSubmit,
      dependentFormState
    );

    if (validationResult.hasErrors) return validationResult;

    let response: Response<PaymentIntentDetailDto> | Response<SubmitFormResponse>;

    if (paymentSessionSessionIdentifier) {
      response = await PaymentIntentsService.updateCheckoutForms({
        identifier: paymentSessionSessionIdentifier,
        body: {
          businessUnitId: businessUnitId,
          accountId: account?.id ?? 0,
          customerId: user?.id ?? 0,
          valuesJson: JSON.stringify(valuesToSubmit),
          paymentSessionSessionIdentifier: `${paymentSessionSessionIdentifier}`,
          formConfigurationId: formId,
          baseFormResponseId: 0,
        },
      });
    } else {
      const request = !user ? FormsService.submitAnonymousForm : FormsService.submitForm;

      response = await request({
        businessUnitId: businessUnitId,
        formConfigurationId: formId,
        body: {
          accountId: account?.id ?? 0,
          customerId: user?.id ?? 0,
          valuesJson: JSON.stringify(valuesToSubmit),
          baseFormResponseId: isDependentForm ? baseFormId : undefined,
          paymentSessionSessionIdentifier: '',
          isDependentForm,
          dependentFormResponses: dependentFormState,
        },
      });
    }

    const submitResults = {
      info: !response.hasErrors,
      error: response.hasErrors,
      content: response.hasErrors
        ? 'An error occurred submitting the form'
        : 'Form successfully submitted',
    };

    if (response.hasErrors) {
      notifications?.error(submitResults.content);
      return;
    }

    notifications?.success(submitResults.content);

    if (!!onComplete) {
      onComplete();
      return;
    } else if (!!onCompleteUrl) {
      navigate(onCompleteUrl);
      return;
    } else if (isDependentForm) {
      const baseFormUrl = location.pathname.split('/').slice(0, -1).join('/');
      const returnUrl = `${baseFormUrl}${location.search}`;

      const existingData = JSON.parse(sessionStore.get(`formConfiguration:${baseFormId}`) ?? '[]');
      sessionStore.set(
        `formConfiguration:${baseFormId}`,
        JSON.stringify([...existingData, response.data])
      );

      navigate(returnUrl);
      return;
    }

    setSubmitResults(submitResults);
  };

  const getInitialValues = useMemo(() => {
    if (!fetchFormConfiguration?.value) return {};

    // Consider timestamping or utilizing UUID
    const sessionFormData = JSON.parse(
      sessionStore.getAndRemove(`formConfigurationState:${formId}`) ?? '{}'
    );

    const mappedValues = mapContextToFields(
      fetchFormConfiguration?.value?.fieldsJson,
      'fields',
      contextData
    );

    return {...mappedValues, ...sessionFormData};
  }, [fetchFormConfiguration?.value, contextData, formId]);

  return (
    <Container css={styles}>
      <Header>{fetchFormConfiguration?.value?.displayName}</Header>
      <Form.Container>
        {submitResults?.content ? (
          <>
            <Message {...submitResults} />
            <div className="form-actions">
              {onComplete ? (
                <Form.Button type="button" onClick={onComplete}>
                  Back
                </Form.Button>
              ) : (
                <Form.Button as={Link} to={onCompleteUrl ?? DEFAULT_ON_FORM_COMPLETE_URL}>
                  Back
                </Form.Button>
              )}
            </div>
          </>
        ) : !!fetchFormConfiguration?.value ? (
          <Form
            initialValues={getInitialValues}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                {getFormFields}
                {getDependentForms(values)}

                <Form.ErrorNotice />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    {isDependentForm ? 'Continue' : 'Submit'}
                  </Form.Button>
                  {onCancel ? (
                    <Form.Button type="button" onClick={onCancel}>
                      Cancel
                    </Form.Button>
                  ) : (
                    <Form.Button
                      as={Link}
                      primary
                      to={onCompleteUrl ?? DEFAULT_ON_FORM_COMPLETE_URL}
                    >
                      Cancel
                    </Form.Button>
                  )}
                </div>
              </>
            )}
          />
        ) : null}
      </Form.Container>
    </Container>
  );
};

const styles = css`
  .ui.button.primary[type='submit'],
  .ui.button.primary[type='button'] {
    background-color: ${payStarColors.primary.blue};
    color: white;

    &:hover,
    &:focus {
      background-color: ${lighten(0.1, payStarColors.primary.blue)} !important;
    }
  }

  ${Media('MobileMax')} {
    .form-actions {
      margin-top: 1rem;
    }
  }

  input:read-only {
    color: grey;
  }

  .ui.radio.checkbox {
    display: flex;
  }

  .ui.checkbox input.hidden + label {
    margin-bottom: 8px;
  }

  .ui.form .ui.header {
    margin-bottom: 0.3rem;
  }

  .dependent-forms-container {
    margin-bottom: 1.25rem;
  }
`;

const failsFieldRequired = (schemaRecord, submittedValues): Error | undefined => {
  const isValid = !schemaRecord.fieldRequired || !!submittedValues[schemaRecord.fieldName];

  return isValid
    ? undefined
    : {
        errorMessage: `${schemaRecord.fieldLabel || 'This'} is a required field.`,
        propertyName: schemaRecord.fieldName,
      };
};

const failsFieldMatches = (schemaRecord, submittedValues, schema): Error | undefined => {
  const isValid =
    !schemaRecord.fieldMatches ||
    `${submittedValues[schemaRecord.fieldName]}`.toLocaleLowerCase() ===
      `${submittedValues[`${schemaRecord.fieldMatches}`]}`.toLocaleLowerCase();

  if (isValid) return undefined;

  const fieldLabelToMatch =
    schema.fields.filter((x) => x.fieldName === schemaRecord.fieldMatches)[0]?.fieldLabel ??
    'Field';

  return isValid
    ? undefined
    : {
        errorMessage: `This field must match ${fieldLabelToMatch}`,
        propertyName: schemaRecord.fieldName,
      };
};

const failsDateLimits = (schemaRecord, submittedValues): Error | undefined => {
  const skipsValidation =
    schemaRecord.fieldType !== 'datepicker' ||
    (schemaRecord.minimumDayOffset === undefined &&
      schemaRecord.maximumDayOffset === undefined &&
      schemaRecord.skipWeekends === undefined);

  if (skipsValidation) return undefined;

  const selectedDateIsOutsideRange = buildIsOutsideRangeForDatePicker(
    schemaRecord.minimumDayOffset,
    schemaRecord.maximumDayOffset,
    schemaRecord.skipWeekends
  );

  const isInvalid = selectedDateIsOutsideRange(moment(submittedValues[schemaRecord.fieldName]));

  return isInvalid
    ? {
        errorMessage: 'The selected date is not available',
        propertyName: schemaRecord.fieldName,
      }
    : undefined;
};

const validateForm = (
  fieldsJson: string,
  valuesToSubmit: any,
  submittedDependentForms: DependentFormResponseDto[]
): Response<unknown> => {
  const parsedFields = JSON.parse(fieldsJson);
  let errors = parsedFields.fields.reduce((errors, curr) => {
    const requiredError = failsFieldRequired(curr, valuesToSubmit);
    if (!!requiredError) return [...errors, requiredError];

    const matchError = failsFieldMatches(curr, valuesToSubmit, parsedFields);
    if (!!matchError) return [...errors, matchError];

    const dateRangeError = failsDateLimits(curr, valuesToSubmit);
    if (!!dateRangeError) return [...errors, dateRangeError];

    return errors;
  }, []);

  if (parsedFields.dependentForms?.length > 0) {
    const allFormsCompleted =
      parsedFields.dependentForms?.length === submittedDependentForms.length &&
      parsedFields.dependentForms.every((x) =>
        submittedDependentForms.some(
          (y) => `${y.formConfigurationId}` === `${x.dependentFormConfigurationId}`
        )
      );

    if (!allFormsCompleted)
      errors = [
        ...errors,
        {
          errorMessage: `Complete additional forms before submitting this form`,
          propertyName: 'dependentForms',
        },
      ];
  }

  const response: Response<unknown> = {
    data: null,
    hasErrors: errors.length > 0,
    errors,
  };

  return response;
};
