import {once, values} from 'lodash';
import oidcClient, {User as OidcUser, UserManager, UserManagerSettings} from 'oidc-client';
import * as Sentry from '@sentry/browser';
import {ClientQueryKeys, CustomSessionClaims} from '../api/generated/enums';
import {Env} from '../config/env-vars';
import {notify} from '../hooks/use-subscription';
import {User} from '../types';
import {logger} from '../utils/logger';

type ClientSettings = {
  BusinessUnitSlug?: string;
  AuthSessionToken?: string;
};

const log = logger('oidc', {namespace: 'oidc-client'});
oidcClient.Log.logger = log;

export let userManager: UserManager;

export const initializeUserManager = once((clientSettings: ClientSettings) => {
  const settings = {
    client_id: 'portal',
    scope: `openid profile paystar.permissions paystar.api`,
    authority: Env.apiBaseUrl || '',
    response_type: 'id_token token',
    post_logout_redirect_uri: Env.appRoot,
    filterProtocolClaims: true,
    redirect_uri: `${Env.appRoot}/callback`,
    silent_redirect_uri: `${Env.appRoot}/silent-refresh.html`,
    automaticSilentRenew: true,

    // Allow user clocks to be off by 1hr 5 minutes. We were running into issues where we
    // may have been missing payments due to user clocks being incorrect. The logic behind
    // 1hr 5 minutes is such that a user can either be under that time, but also handle
    // the scenarion where the time is "correct" just missing the DST setting
    clockSkew: 60 * 65,

    extraQueryParams: clientSettings,
  } as UserManagerSettings;

  const slug = clientSettings.BusinessUnitSlug;

  if (slug) {
    Sentry.setTag(ClientQueryKeys.BusinessUnitSlug, slug);
  }

  if (clientSettings.AuthSessionToken) {
    Sentry.setExtra(ClientQueryKeys.AuthSessionToken, clientSettings.AuthSessionToken);
  }

  userManager = new UserManager(settings);

  userManager.events.addSilentRenewError(() => {
    notify('session-expired', undefined);
  });

  userManager.events.addUserSessionChanged(() => {
    log.info('UserSessionChanged');
  });

  userManager.events.addAccessTokenExpired(() => {
    notify('session-expired', undefined);
  });

  userManager.events.addAccessTokenExpiring(() => {
    log.warn('AccessTokenExpiring');
  });

  userManager.events.addUserSignedOut(async () => {
    await userManager.removeUser();
    window.location.reload();
  });
});

export const getUser = async (): Promise<OidcUser | null> => {
  const user = await userManager.getUser();
  return user && !user.expired ? user : null;
};

export const mapOidcUser = (user: OidcUser): User => {
  const sessionAttributes = values(CustomSessionClaims).reduce((acc, key) => {
    const value = user.profile[key];
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {}) as User['attributes'];
  return {
    id: Number(user.profile.sub),
    name: user.profile.name || '',
    email: user.profile.preferred_username || '',
    permissions: user.profile.permissions,
    role: user.profile.role,
    attributes: sessionAttributes,
  };
};
