import '../api/generated/config';

import React, {useContext, useEffect} from 'react';
import {Redirect, RouteComponentProps, Router, useLocation} from '@reach/router';
import {analytics} from '../analytics';
import {setUser} from '../analytics/sentry';
import {CustomerResolver} from '../customer-portal/customer-resolver';
import {appSubdirectory, buildPath, rootRoutes, routes} from '../routes';
import {logger} from '../utils/logger';
import {SignOut} from './sign-out';
import {AuthContext, useUser} from './use-auth';
import {userManager} from '.';

const AdminApp = React.lazy(() => import('../paystar-portal/app'));
const CustomerApp = React.lazy(() => import('../customer-portal/app'));

const log = logger('auth');

const AuthenticationResolver = () => {
  const location = useLocation();
  useEffect(() => {
    analytics.trackPage(location.pathname);
  }, [location]);

  return (
    <>
      <Router>
        <AdminApp path={routes.portal.base} />
        <CustomerApp path={routes.customer.base} />
        <CustomerResolver path={rootRoutes.customer} />
        <SignOut path={routes.portal.signOut} />
        <DefaultRouteResolver default />
      </Router>
      <AuthenticationCleanup />
    </>
  );
};

const DefaultRouteResolver: React.FC<RouteComponentProps> = () => {
  const {redirectUrl = ''} = useContext(AuthContext);
  const user = useUser();

  let userDefaultUrl = rootRoutes.portal;

  let slug: string;
  if (user.role === 'QuickPay' || user.role === 'Customer') {
    slug = user.attributes?.business_unit_slug || '';
    userDefaultUrl = slug ? buildPath(routes.customer.dashboard, {slug}) : rootRoutes.customer;
  }

  let url = redirectUrl || userDefaultUrl || rootRoutes.portal;
  const useUserDefault = url === '/' || url === appSubdirectory || url === `${appSubdirectory}/`;

  if (useUserDefault) {
    url = userDefaultUrl;
  }

  log.info('redirectUrl', {
    role: user.role,
    url,
    userDefaultUrl,
    redirectUrl,
    appSubdirectory,
  });

  return <Redirect to={url} noThrow />;
};

const AuthenticationCleanup = () => {
  const user = useUser();

  useEffect(() => {
    userManager.clearStaleState();
    setUser({id: `${user.id}`, email: user.email});
  }, [user.email, user.id]);

  return null;
};

export default AuthenticationResolver;
