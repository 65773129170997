import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {Button as SemanticButton, ButtonProps} from 'semantic-ui-react';
import {cx} from '@emotion/css';

import {RequireOnlyOne} from '../types';

type ButtonTypeProps = {
  type?: 'button' | 'submit';
  as?: any;
};

export type Button = ButtonProps &
  RequireOnlyOne<ButtonTypeProps, 'type' | 'as'> & {
    tertiary?: boolean;
  };

const ButtonWithType = ({type, secondary, tertiary, className, ...props}: Button) => {
  const classNames = cx(className, {
    secondary: !!secondary,
    tertiary: !!tertiary,
  });

  return <SemanticButton type={type} {...props} className={classNames} />;
};

export const Button = hoistNonReactStatics(ButtonWithType, SemanticButton) as typeof ButtonWithType;
