import {css} from '@emotion/react/macro';
import {includes} from 'lodash';
import React from 'react';
import {FieldArray} from 'react-final-form-arrays';
import {Checkbox} from 'semantic-ui-react';

const toggle = (fields, checked, option) => {
  const index = (fields.value || []).findIndex((x) => x === option);
  if (checked) {
    fields.push(option);
  } else if (index > -1) {
    fields.remove(index);
  }
};

type MultiSelectCheckbox = {
  fieldName: string;
  options: MultiSelectCheckboxOption[];
  fieldLabel?: string;
  defaultCheckedKeys?: string[];
  readOnlyKeys?: string[];
};

type MultiSelectCheckboxOption = {
  displayName: string;
  value: string;
};

export const MultiSelectCheckbox: React.FC<MultiSelectCheckbox> = ({
  fieldName,
  options,
  fieldLabel,
  defaultCheckedKeys = [],
  readOnlyKeys = [],
}) => {
  return (
    <FieldArray
      name={fieldName}
      render={(props) => (
        <div className="form-field" css={styles}>
          <label>{fieldLabel}</label>

          {options.map((option) => (
            <div className="inline-label" key={option.value}>
              <Checkbox
                type="checkbox"
                id={`${props.fields.name}_${option.value}`}
                checked={
                  includes(props.fields.value, option.value) ||
                  defaultCheckedKeys.includes(option.value)
                }
                onChange={(event, {checked}) => toggle(props.fields, checked, option.value)}
                disabled={readOnlyKeys.includes(option.value)}
              />
              <label htmlFor={`${props.fields.name}_${option.value}`}>{option.displayName}</label>
            </div>
          ))}
        </div>
      )}
    />
  );
};

const styles = css`
  > label {
    display: block;
    margin: 0em 0em 0.28571429rem 0em;
    font-size: 0.92857143em;
    color: #444;
    font-weight: bold;
    display: block;
  }

  .inline-label {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    > label {
      flex: 1 1 0%;
      padding-left: 0.3rem;
      margin-left: 0.3rem;

      &:hover {
        cursor: pointer;
        background: #efefef;
        border-radius: 2px;
      }
    }
  }
`;
