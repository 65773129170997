import React from 'react';
import {Radio} from 'semantic-ui-react';
import {AnyObject, RequireOnlyOne} from '../../types';
import {DropdownOption, getEnumDropdownOptions} from '../../api/generated/utils';

export type RadioGroupAdapterProps = RequireOnlyOne<
  {
    options?: DropdownOption[];
    enum?: AnyObject;
  },
  'options' | 'enum'
>;

export const RadioGroupAdapter = ({input, meta, ...rest}) => {
  const {options, enum: enumObject} = rest as RadioGroupAdapterProps;
  const _options = (enumObject ? getEnumDropdownOptions(enumObject) : options) as DropdownOption[];

  return (
    <div>
      {_options.map((option) => (
        <Radio
          key={option.value}
          label={option.text}
          name={input.name}
          value={option.value}
          checked={input.value === option.value}
          onChange={(event, {value}) => input.onChange(value)}
        />
      ))}
    </div>
  );
};
