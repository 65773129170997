import {Image} from '@react-pdf/renderer';
import bwipjs from 'bwip-js';

type BarcodeProps = {
  dataToEncode: string;
};

export const Barcode: React.FC<BarcodeProps> = ({dataToEncode}) => {
  const canvas = document.createElement('canvas');

  try {
    bwipjs.toCanvas(canvas, {
      bcid: 'code128',
      text: `${dataToEncode}`,
      scale: 3,
      height: 10,
      includetext: false,
      textxalign: 'center',
    });
  } catch (e) {
    console.error('BWIP Error:', e);
  }
  return <Image source={canvas.toDataURL()} />;
};

declare module 'bwip-js' {
  export function toCanvas(canvas: HTMLCanvasElement, opts: any): void;
}
