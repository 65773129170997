import {Global} from '@emotion/react';
import {css} from '@emotion/react/macro';
import moment, {Moment} from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {useForm, useFormState} from 'react-final-form';
import {Header, HtmlInputrops, StrictInputProps} from 'semantic-ui-react';
import nameof from 'ts-nameof.macro';
import {DateRangePickerPhrases} from '../../aria/date-picker';
import {DateFormat} from '../../components/date';
import {payStarColors} from '../../styles/styled';
import {Button} from '../button';
import {
  DateRangePicker,
  SingleDatePicker,
  AnchorDirectionShape,
  SingleDatePickerShape,
} from 'react-dates';

type OmittedDatePickerProps = 'id' | 'focused' | 'onFocusChange' | 'date' | 'onDateChange';

type DatePickerShape = Omit<SingleDatePickerShape, OmittedDatePickerProps>;

export type DatePickerAdapterProps = StrictInputProps & HtmlInputrops & DatePickerShape;

export type DateRangeAdapterProps = StrictInputProps &
  HtmlInputrops & {
    startDateProperty: string;
    endDateProperty: string;
    onClose?: () => void;
    anchorDirection?: AnchorDirectionShape;
    hideQuickSelection?: boolean;
  };

export const DatePickerAdapter = ({input, meta, isOutsideRange = () => false}) => {
  const [focused, setFocused] = useState(false);
  const last = useRef<Moment | undefined>();

  const getDate = (date) => {
    if (last.current !== date) {
      last.current = date ? moment(date) : undefined;
    }

    return last.current;
  };

  return (
    <div css={datePickerStyles}>
      <SingleDatePicker
        id={input.name}
        inputIconPosition="after"
        focused={focused}
        onFocusChange={({focused}) => setFocused(focused as any)}
        noBorder
        enableOutsideDays
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        date={getDate(input.value) as any}
        onDateChange={(date) => {
          input.onChange(date ? date.startOf('day').toDate().toISOString() : date);
        }}
      />
    </div>
  );
};

const getDate = (date) => (date ? moment(date) : undefined);

export const DateRangeCustomField: React.FC<DateRangeAdapterProps> = (props) => {
  const state = useFormState();
  const startDate = getDate(state.values[props.startDateProperty]);
  const endDate = getDate(state.values[props.endDateProperty]);

  const form = useForm(nameof(DateRangeCustomField));

  useEffect(() => {
    form.registerField(props.startDateProperty, () => {}, {dirty: true});
    form.registerField(props.endDateProperty, () => {}, {dirty: true});
  }, [form, props.endDateProperty, props.startDateProperty]);

  const onDatesChanged = ({startDate, endDate}) => {
    form.batch(() => {
      form.change(props.startDateProperty, startDate ? startDate.toISOString() : startDate);
      form.change(props.endDateProperty, endDate ? endDate.toISOString() : endDate);
      form.blur(props.startDateProperty);
      form.blur(props.endDateProperty);
    });
  };

  return (
    <DateRange
      startDate={startDate as any}
      startDateProperty={props.startDateProperty}
      endDate={endDate as any}
      endDateProperty={props.endDateProperty}
      onDatesChanged={onDatesChanged}
      anchorDirection={props.anchorDirection}
      hideQuickSelection={props.hideQuickSelection}
      onClose={() => props.onClose?.()}
    />
  );
};

type Dates = {startDate: Moment | undefined; endDate: Moment | undefined};
type ChangeActionProps =
  | {
      onClose: (dates: Dates) => void;
      onDatesChanged?: (dates: Dates) => void;
    }
  | {
      onClose?: (dates: Dates) => void;
      onDatesChanged: (dates: Dates) => void;
    }
  | {
      onClose: (dates: Dates) => void;
      onDatesChanged: (dates: Dates) => void;
    };

type DateRangeProps = StrictInputProps &
  ChangeActionProps &
  HtmlInputrops & {
    startDateProperty: string;
    startDate?: Moment | string;
    endDateProperty: string;
    endDate?: Moment | string;
    anchorDirection?: AnchorDirectionShape;
    hideQuickSelection?: boolean;
  };

export const DateRange: React.FC<DateRangeProps> = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const {startDateProperty, endDateProperty} = props;
  const startDate = getDate(props.startDate);
  const endDate = getDate(props.endDate);

  const getDates = ({startDate, endDate}) => {
    const startOfStartDate: Moment | undefined = startDate?.startOf('day');
    const endOfEndDate: Moment | undefined = endDate?.endOf('day');

    const dates: Dates = {
      startDate: startOfStartDate,
      endDate: endOfEndDate,
    };
    return dates;
  };

  const onDatesChanged = ({startDate, endDate}) =>
    props.onDatesChanged?.(getDates({startDate, endDate}));

  const onClose = ({startDate, endDate}) => props.onClose?.(getDates({startDate, endDate}));

  return (
    <>
      <Global styles={datePickerStyles} />
      <DateRangePicker
        startDate={startDate as any}
        startDateId={startDateProperty}
        endDate={endDate as any}
        endDateId={endDateProperty}
        onDatesChange={onDatesChanged}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput as any);
        }}
        noBorder
        enableOutsideDays
        appendToBody
        anchorDirection={props.anchorDirection}
        isOutsideRange={() => false}
        numberOfMonths={1}
        minimumNights={0}
        renderCalendarInfo={() =>
          !props.hideQuickSelection && (
            <CalendarQuickSelection
              onDatesChanged={({startDate, endDate}) => {
                onDatesChanged({startDate, endDate});
                onClose({startDate, endDate});
              }}
              requestClose={() => setFocusedInput(null)}
            />
          )
        }
        calendarInfoPosition="before"
        phrases={DateRangePickerPhrases}
        onClose={onClose}
      />
    </>
  );
};

type CalendarQuickSelection = {
  onDatesChanged: (dates: {startDate: Moment; endDate: Moment}) => void;
  requestClose: () => void;
};

const CalendarQuickSelection: React.FC<CalendarQuickSelection> = ({
  onDatesChanged,
  requestClose,
}) => {
  const {
    today,
    yesterday,
    startOfWeek,
    startOfLastWeek,
    endOfLastWeek,
    startOfMonth,
    startOfLastMonth,
    endOfLastMonth,
  } = useMemo(() => {
    const today = moment().startOf('date').toDate();
    const yesterday = moment().startOf('date').subtract(1, 'day').toDate();
    const startOfWeek = moment().startOf('week').toDate();

    const startOfLastWeek = moment().startOf('week').subtract(1, 'week').toDate();

    const endOfLastWeek = moment(startOfLastWeek).add(1, 'week').subtract(1, 'day').toDate();

    const startOfMonth = moment().startOf('month').toDate();

    const startOfLastMonth = moment().subtract(1, 'month').startOf('month').toDate();

    const endOfLastMonth = moment().subtract(1, 'month').endOf('month').toDate();

    return {
      today,
      yesterday,
      startOfWeek,
      startOfLastWeek,
      endOfLastWeek,
      startOfMonth,
      startOfLastMonth,
      endOfLastMonth,
    };
  }, []);

  return (
    <div className="DateRangePicker__actions">
      <Header>Quick Selection</Header>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(today),
            endDate: moment(today).endOf('day'),
          });
          requestClose();
        }}
      >
        <strong>{'Today: '}</strong>
        <DateFormat date={today} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(yesterday),
            endDate: moment(yesterday).endOf('day'),
          });
          requestClose();
        }}
      >
        <strong>{'Yesterday: '}</strong>
        <DateFormat date={yesterday} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfWeek),
            endDate: moment(today).endOf('day'),
          });
          requestClose();
        }}
      >
        <strong>{'This Week: '}</strong>
        <DateFormat date={startOfWeek} />
        {' - '}
        <DateFormat date={today} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfLastWeek),
            endDate: moment(endOfLastWeek).endOf('day'),
          });
          requestClose();
        }}
      >
        <strong>{'Last Week: '}</strong>
        <DateFormat date={startOfLastWeek} />
        {' - '}
        <DateFormat date={endOfLastWeek} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfLastMonth),
            endDate: moment(endOfLastMonth),
          });
          requestClose();
        }}
      >
        <strong>{'Last Month: '}</strong>
        <DateFormat date={startOfLastMonth} />
        {' - '}
        <DateFormat date={endOfLastMonth} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfMonth),
            endDate: moment(today).endOf('day'),
          });
          requestClose();
        }}
      >
        <strong>{'MTD: '}</strong>
        <DateFormat date={startOfMonth} />
        {' - '}
        <DateFormat date={today} />
      </Button>
    </div>
  );
};

const datePickerStyles = css`
  .DateInput_input {
    font-weight: 400 !important;
  }

  .SingleDatePicker,
  .SingleDatePicker > div,
  .SingleDatePickerInput,
  .SingleDatePickerInput > div {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .SingleDatePicker .DateInput_input[readonly] {
    background-color: #fff !important;
    font-weight: 400 !important;
  }

  .DateInput_fang {
    margin-top: 1px;
  }

  .DayPicker__withBorder {
    box-shadow: 0px 1px 11px 1px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(0, 0, 0, 0.07) !important;
  }

  .DateRangePicker_picker {
    /* z-index: 100; */
  }

  .DateRangePicker {
    border: solid 2px #cbcfd1;
    border-radius: 4px;

    .DateInput_input {
      border: none !important;
      background: #fff !important;
      font-weight: normal;
    }

    .DateRangePickerInput_arrow {
      margin: 0 5px;
    }

    .DateInput {
      width: 128px;
    }

    .DateInput:last-child .DateInput_input {
      text-align: right;
    }

    .DateInput_input__focused {
      outline: solid #85b7d9 2px !important;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${payStarColors.blue3};
    border: 1px double ${payStarColors.blue3};
    color: #fff;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active {
    background: ${payStarColors.blue4};
    border: 1px double ${payStarColors.blue4};
    color: #fff;
  }

  .DayPicker {
    background: #f1f1f1;
  }

  .DayPicker_wrapper__horizontal {
    background: #fff;
  }

  .DayPicker_transitionContainer {
    border-right: solid 1px #d2d2d2;
  }

  .DateRangePicker__actions {
    padding: 20px;

    .ui.button {
      text-align: left;

      strong {
        width: 85px;
        display: inline-block;
      }
    }
  }
`;
