/* eslint-disable */

import {
  PaymentDetailDto,
  Currency,
  TransactionDetailDto,
  PaymentSummaryDto,
  PaymentSourceJsonDto,
  PaymentSessionLineItemGetDto,
  AccountSummaryDto,
  OptionalAddress,
  PaymentLookupDto,
  AdvancedQueryColumnFilter,
  AdvancedQueryColumnSort,
  NetRevenueTransactionMetricsResponse,
  SendPaymentReceiptRequest,
  EmptyResponse,
  Error,
  VoidPaymentRequest,
  TransactionGetDto,
  RefundPaymentRequest,
  ChargebackRequest,
  AccountPaymentsDto,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class PaymentsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getDetails(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLookup(
    params: {
      /**  */
      referenceNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentLookupDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/lookup/{referenceNumber}';
      url = url.replace('{referenceNumber}', params['referenceNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsByReferenceNumber(
    params: {
      /**  */
      referenceNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/reference-number/{referenceNumber}';
      url = url.replace('{referenceNumber}', params['referenceNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaries(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      paymentStatuses?: string[];
      /**  */
      paymentSourceTypes?: string[];
      /**  */
      paymentChannels?: string[];
      /**  */
      reportName?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<PaymentSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/payments';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PaymentStatuses: params['paymentStatuses'],
        PaymentSourceTypes: params['paymentSourceTypes'],
        PaymentChannels: params['paymentChannels'],
        ReportName: params['reportName'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNetRevenueMetrics(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<NetRevenueTransactionMetricsResponse>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/net-revenue-metrics';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StartDate: params['startDate'], EndDate: params['endDate'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReceipt(
    params: {
      /**  */
      paymentId: number;
      /**  */
      body?: SendPaymentReceiptRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{paymentId}/send-receipt';
      url = url.replace('{paymentId}', params['paymentId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static void(
    params: {
      /**  */
      id: number;
      /**  */
      body?: VoidPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<TransactionGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{id}/void';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refund(
    params: {
      /**  */
      id: number;
      /**  */
      body?: RefundPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<TransactionGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{id}/refund';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static chargeback(
    params: {
      /**  */
      id: number;
      /**  */
      body?: ChargebackRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{id}/chargeback';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllAccountPayments(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      paymentSourceTypes?: string[];
      /**  */
      paymentChannels?: string[];
      /**  */
      searchText?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountPaymentsDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{businessUnitId}/account-payments';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PaymentSourceTypes: params['paymentSourceTypes'],
        PaymentChannels: params['paymentChannels'],
        SearchText: params['searchText'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadAccountPaymentsPdf(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      paymentSourceTypes?: string[];
      /**  */
      paymentChannels?: string[];
      /**  */
      searchText?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{businessUnitId}/account-payments/download/pdf';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        PaymentSourceTypes: params['paymentSourceTypes'],
        PaymentChannels: params['paymentChannels'],
        SearchText: params['searchText'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
