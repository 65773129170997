/* eslint-disable */

import {
  CustomerDetailDto,
  AdvancedQueryColumnFilter,
  AdvancedQueryColumnSort,
  UpdateCustomerRequest,
  CustomerGetDto,
  InitiateCustomerPhoneVerificationRequest,
  PhoneVerificationIdentifierDto,
  VerifyCustomerPhoneRequest,
  LinkCustomerAccountRequest,
  AccountDetailDto,
  OptionalAddress,
  CustomerAccountGetDto,
  AutopayEnrollmentSettings,
  ScheduledPaymentCustomerSummaryDto,
  ViewCustomerAccounts,
  UnlinkCustomerAccountAsCustomerRequest,
  UnlinkCustomerAccountAsAdminRequest,
  AutopayActivityLogSummaryDto,
  PaymentSourceSummaryDto,
  AutopayUpdateEnrollmentRequest,
  AutopayEnrollmentResponse,
  EmptyResponse,
  Error,
  ViewTransactionAggregate,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CustomersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getByUserId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustomerDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/by-user/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByAccountId(
    params: {
      /**  */
      accountId: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<CustomerDetailDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/by-account/{accountId}';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerSlug(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/business-unit-slug';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id?: number;
      /**  */
      body?: UpdateCustomerRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustomerGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static initiatePhoneVerification(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      body?: InitiateCustomerPhoneVerificationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PhoneVerificationIdentifierDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/initiate-phone-verification/{businessUnitId}';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyPhone(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      body?: VerifyCustomerPhoneRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/verify-phone/{businessUnitId}';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static linkCustomerAccount(
    params: {
      /**  */
      body?: LinkCustomerAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/accounts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCustomerAccounts(
    params: {
      /**  */
      businessUnitId?: number;
      /**  */
      customerId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<ViewCustomerAccounts>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/accounts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BusinessUnitId: params['businessUnitId'],
        CustomerId: params['customerId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlinkCustomerAccountAsCustomer(
    params: {
      /**  */
      body?: UnlinkCustomerAccountAsCustomerRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/accounts/as-customer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlinkCustomerAccountAsAdmin(
    params: {
      /**  */
      body?: UnlinkCustomerAccountAsAdminRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/accounts/as-admin';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAutopayEnrollmentHistoryByAccountId(
    params: {
      /**  */
      accountId: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AutopayActivityLogSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/{accountId}/autopay-enrollment-history';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAutopaySettings(
    params: {
      /**  */
      customerId: number;
      /**  */
      accountId: number;
      /**  */
      body?: AutopayUpdateEnrollmentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AutopayEnrollmentResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/{customerId}/{accountId}/autopay-settings';
      url = url.replace('{customerId}', params['customerId'] + '');
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateLoginLink(
    params: {
      /**  */
      customerId?: number;
      /**  */
      accountId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/generate-login-link';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'], accountId: params['accountId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCustomers(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<CustomerDetailDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/by-business-unit/{businessUnitId}';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCustomerById(
    params: {
      /**  */
      customerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustomerDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCustomerAccountPayments(
    params: {
      /**  */
      businessUnitId?: number;
      /**  */
      accountIds?: number[];
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<ViewTransactionAggregate>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/payments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BusinessUnitId: params['businessUnitId'],
        AccountIds: params['accountIds'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllAccountsEnrolledInAutopay(
    params: {
      /**  */
      businessUnitId?: number;
      /**  */
      customerId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<ViewCustomerAccounts>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers/autopay-enabled-accounts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BusinessUnitId: params['businessUnitId'],
        CustomerId: params['customerId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
