import React from 'react';
import {useField} from 'react-final-form';
import Alert from '@reach/alert';

export const SubmitError = ({name}) => {
  const {
    meta: {submitError},
  } = useField(name, {
    subscription: {touched: true, submitError: true},
  });

  return submitError ? (
    <div className="sui-error-message">
      <Alert>{submitError}</Alert>
    </div>
  ) : null;
};
