import {Container, Header, Image, Message, Popup, Segment, Table} from 'semantic-ui-react';
import {Media, useBreakpoint} from '../styles/breakpoints';
import {PaymentLineItemTypes, PaymentTypes} from '../api/generated/enums';
import {RouteComponentProps, useNavigate} from '@reach/router';
import {RouteParams, buildPath, routes} from '../routes';

import Currency from '../components/currency';
import {DateFormat} from '../components/date';
import {FlexRowWithActions} from '../components/flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PaymentsService} from '../api/generated/PaymentsService';
import React from 'react';
import {css} from '@emotion/react';
import {cx} from '@emotion/css';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {useAsync} from 'react-use';

export const PaymentLookup = (props: RouteComponentProps) => {
  const {referenceNumber} = props as RouteParams<{referenceNumber: string}>;
  const breakpoint = useBreakpoint();
  const showMobileLogo = ['MobileMax', 'TabletMin', 'TabletMax'].some((x) => x === breakpoint);
  const navigate = useNavigate();

  const fetchPayment = useAsync(async () => {
    const {data} = await PaymentsService.getLookup({referenceNumber});

    if (data?.redirectReferenceNumber) {
      navigate(buildPath(routes.paymentLookup, {referenceNumber: data.redirectReferenceNumber}), {
        replace: true,
      });
    }

    return data;
  }, [navigate, referenceNumber]);

  const payment = fetchPayment.value;

  if (!payment) {
    return (
      <div css={notFoundStyles}>
        <Segment>
          <Message icon="search" content={`Payment "${referenceNumber}" not found.`} />
        </Segment>
      </div>
    );
  }

  const serviceFeeIsIncludedInBasePayment =
    payment.relatedPayments?.length === 0 ||
    !payment.relatedPayments?.some((x) => x.paymentType === PaymentTypes['Service Fee']);

  return (
    <div>
      <Container text css={styles}>
        <Segment
          className={cx('branding-container', {
            'has-logo': Boolean(''),
          })}
        >
          <FlexRowWithActions
            actions={
              <>
                {!showMobileLogo && payment.businessUnitLogoUrl && (
                  <Image
                    src={payment.businessUnitLogoUrl}
                    centered
                    className="logo"
                    alt="Company Logo"
                  />
                )}

                {showMobileLogo && payment.businessUnitMobileLogoUrl && (
                  <Image
                    src={payment.businessUnitMobileLogoUrl}
                    centered
                    className="logo"
                    alt="Company Logo"
                  />
                )}
              </>
            }
            actionsPosition={breakpoint === 'MobileMax' ? 'left' : 'right'}
          >
            <Header
              size={breakpoint === 'MobileMax' ? 'small' : undefined}
              textAlign={breakpoint === 'MobileMax' ? 'center' : undefined}
            >
              {payment.businessUnitHeader}
              <Header.Subheader>{payment.businessUnitSubHeader}</Header.Subheader>
            </Header>
          </FlexRowWithActions>
        </Segment>
        <Segment>
          <Header sub>Details</Header>
          <Table className="details">
            <Table.Body>
              <Table.Row>
                <Table.Cell>Payment Date</Table.Cell>
                <Table.Cell>
                  <DateFormat date={payment.createdDate} format="MM/DD/YYYY hh:mm A" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>{payment.hasAdjustment ? 'Returned' : payment.status}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Reference #</Table.Cell>
                <Table.Cell>{referenceNumber}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{payment.paymentDescriptorLabel || 'Payment Descriptor'}</Table.Cell>
                <Table.Cell>{payment.paymentDescriptor}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Payment Method</Table.Cell>
                <Table.Cell>{payment.paymentMethod}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Last 4 Digits of Payment Method</Table.Cell>
                <Table.Cell>{payment.paymentSourceAccountNumberLast4}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Auth Code</Table.Cell>
                <Table.Cell>{payment.authCode}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {payment.lineItems.length > 0 && (
            <>
              <br />
              <Header sub>Payment Items</Header>
              <Table columns={2}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {payment.lineItems.map((x) => (
                    <Table.Row key={x.id}>
                      <Table.Cell>
                        {x.description}{' '}
                        {x.lineItemType === PaymentLineItemTypes.ServiceFee && (
                          <ServiceFeeTooltip
                            includedInBasePayment={serviceFeeIsIncludedInBasePayment}
                            org={payment.businessUnitHeader}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Currency amount={x.price.value} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <b>Total</b>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <b>
                        <Currency amount={payment.amountMinor} />
                      </b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {payment.relatedPayments.length > 0 && (
                <>
                  <Header sub>Related Payments</Header>
                  <Table columns={2}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {payment.relatedPayments.map((x) => (
                        <Table.Row key={x.id}>
                          <Table.Cell>
                            {x.paymentType === PaymentTypes['Service Fee'] ? (
                              <>
                                Service Fee{' '}
                                <ServiceFeeTooltip
                                  includedInBasePayment={serviceFeeIsIncludedInBasePayment}
                                  org={payment.businessUnitHeader}
                                />
                              </>
                            ) : (
                              x.paymentDescriptor
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Currency amount={x.amountMinor} />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </>
              )}
            </>
          )}
        </Segment>
      </Container>
    </div>
  );
};

type ServiceFeeTooltipProps = {
  includedInBasePayment: boolean;
  org: string;
};

const ServiceFeeTooltip: React.FC<ServiceFeeTooltipProps> = ({includedInBasePayment, org}) => (
  <Popup
    trigger={<FontAwesomeIcon icon={faInfoCircle} />}
    position="top center"
    content={
      includedInBasePayment
        ? 'The Service Fee is a related fee because ' +
          org +
          ' partners with Paystar to provide online payment processing.'
        : 'The Service Fee is a related fee because ' +
          org +
          ' partners with Paystar to provide online payment processing. ' +
          org +
          ' will be directly above or below the Paystar charge on your statement.'
    }
  />
);

const notFoundStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  .ui.segment {
    width: 600px;
  }
`;

const styles = css`
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: whitesmoke;

  .details-header {
    display: flex;
    align-items: end;
  }

  .details {
    tr {
      td:first-of-type {
        font-weight: bold;
      }
    }
  }

  .ui.sub.header {
    text-decoration: underline;
  }

  .logo {
    max-height: 3rem !important;
    width: auto !important;
    padding: 0 0.5rem;

    ${Media('TabletMin')} {
      padding: 0 0.5rem;
    }
  }
`;
