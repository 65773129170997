import {mapValues, values} from 'lodash';
import qs from 'qs';
import {Env} from '../../config/env-vars';
import {AnyObject} from '../../types';
import {logger} from '../../utils/logger';

const log = logger('code-gen utils');

export type DropdownOption = {
  text: string;
  value: any;
};

export const getEnumDropdownOptions = (() => {
  const enumMapCache = new WeakMap();

  return (enumObject: AnyObject) => {
    if (!enumObject) {
      throw new Error('invalid enum cache object');
    }
    if (enumMapCache.has(enumObject)) {
      return enumMapCache.get(enumObject) as DropdownOption[];
    }

    log.info('enum cache miss', enumObject);
    const enumOptions = values(enumObject).map((x) => ({
      text: x,
      value: x,
      key: x,
    }));

    enumMapCache.set(enumObject, enumOptions);
    return enumOptions as DropdownOption[];
  };
})();

export const validateEnumOptions = (() => {
  const enumMapCache = new WeakMap();

  return (enumObject: AnyObject, value: string) => {
    if (!enumObject) {
      throw new Error('invalid enum cache object');
    }
    if (enumMapCache.has(enumObject)) {
      return Boolean(enumMapCache.get(enumObject)[value]);
    }

    log.info('enum cache miss', enumObject);
    const enumOptions = mapValues(enumObject, (value) => value);

    enumMapCache.set(enumObject, enumOptions);
    return Boolean(enumOptions[value]);
  };
})();

export const buildApiUrl = (url: string, params?: AnyObject) => {
  const baseUrl = Env.apiBaseUrl;
  const queryString = params ? '?' + qs.stringify(params, {allowDots: true}) : '';
  return `${baseUrl}${url}${queryString}`;
};
