import {css} from '@emotion/react/macro';
import {faCaretDown, faCaretRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Header, Segment} from 'semantic-ui-react';
import {Env} from '../config/env-vars';
import {AnyObject} from '../types';

export const Json = React.memo<{data: AnyObject; defaultOpen?: boolean}>(({data, defaultOpen}) => {
  const [open, setOpen] = useState(defaultOpen || false);

  if (Env.isProductionBuild) {
    return null;
  }

  const icon = open ? faCaretDown : faCaretRight;

  return (
    <div css={styles}>
      <Header attached="top" inverted onClick={() => setOpen(!open)}>
        Debug <FontAwesomeIcon icon={icon} />
      </Header>
      {open && (
        <Segment attached>
          <pre className="debug-container">{JSON.stringify(data, null, 2)}</pre>
        </Segment>
      )}
    </div>
  );
});

export const JsonViewerLazy = React.lazy(() => import('./json-viewer'));

export const Pre = React.memo<{data: AnyObject}>(({data}) => (
  <Segment attached>
    <pre css={styles}>{JSON.stringify(data, null, 2)}</pre>
  </Segment>
));

const styles = css`
  margin-top: 1rem;

  .ui.header:first-of-type {
    margin-top: 0;
  }
  .ui.segment {
    background-color: #eaeaea;
    max-height: 400px;
    overflow-y: auto;
    font-family: monospace;
  }
`;
