import {darken, lighten, textInputs, transparentize} from 'polished';
import React from 'react';
import {useAsync} from 'react-use';
import {Loader} from 'semantic-ui-react';
import {Global} from '@emotion/react';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';
import {css} from '@emotion/react/macro';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import styled, {defaultTheme, payStarColors} from './styled';

type Theme = typeof defaultTheme;

type ThemeProvider = {
  children: React.ReactNode;
  resolveTheme: () => Promise<Theme>;
};

export const ThemeContainer = styled.div`
  .ui {
    &.buttons .button,
    &.button {
      font-weight: normal;

      &.primary {
        background-color: ${(props) => props.theme.primaryButtonBg || payStarColors.primary.blue};

        &:hover,
        &:focus {
          &:not(.basic) {
            background-color: ${(props) =>
              lighten(0.1, props.theme.primaryButtonBg || payStarColors.primary.blue)} !important;
          }
        }
      }

      &.secondary {
        background-color: ${(props) => props.theme.secondaryButtonBg || '#e8e8e8'};
        box-shadow: 0px 0px 0px 1px ${(props) => props.theme.secondaryButtonShadow} !important;
        color: ${(props) => props.theme.secondaryButtonText || payStarColors.textLabel};

        &:hover,
        &:focus {
          background-color: ${(props) =>
            lighten(0.1, props.theme.secondaryButtonBg || '#e8e8e8')} !important;
        }
      }
    }
  }

  a {
    color: ${(props) => props.theme.primaryColor || payStarColors.primary.blue};

    &.hint {
      color: ${(props) => lighten(0.1, props.theme.primaryColor || payStarColors.primary.blue)};
    }
  }
`;

const globalStyles = css`
  .root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ui {
    &.form {
      ${textInputs()} {
        border-width: 2px;
        border-color: ${payStarColors.grey2};

        &:focus {
          outline: -webkit-focus-ring-color auto 3px;
        }

        &[readonly] {
          background: #f1f1f1;
          color: #6b6b6b;
          &:focus {
            outline: none;
            background: #f1f1f1;
            color: #6b6b6b;
          }
        }
      }

      .field.error {
        ${textInputs()} {
          background: #fff;
          border-color: #e0b4b4;
          color: #9f3a38;
          box-shadow: none;
        }
      }

      .dropdown,
      .selection.dropdown {
        border-width: 2px;
        border-color: ${payStarColors.grey2};

        .menu {
          border-width: 2px;
          border-color: ${payStarColors.grey2};
          margin: 0px -2px;
          min-width: calc(100% + 4px);
          width: calc(100% + 4px);
        }

        &:not(.button) > .default.text,
        &.default:not(.button) > .text {
          color: ${payStarColors.grey1};
        }
      }

      .field > label,
      .field-label {
        color: ${payStarColors.textLabel};
        font-weight: normal;
        font-size: 0.92857143em;

        .hint {
          float: right;
        }
      }

      .success.message,
      .warning.message,
      .error.message {
        display: block;
      }

      .error.error input:-webkit-autofill {
        box-shadow: inherit !important;
        border-color: #cbcfd1 !important;
      }
    }

    &.input {
      &.labeled:not([class*='corner labeled']) .label {
        line-height: 1.3rem;
        border-width: 2px;
        border-color: ${payStarColors.grey2};
        padding-top: calc(0.78571429em - 2px);
        padding-bottom: calc(0.78571429em - 2px);
        background-color: #fff;

        &:first-of-type + input {
          border-left: 0;
        }
      }

      &.action {
        > .button {
          border: solid 2px ${payStarColors.grey2} !important;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        &:not([class*='left action']) {
          > input {
            border-right-color: ${payStarColors.grey2} !important;
          }

          > .button:last-child {
            border-left: none !important;
          }
        }
      }

      &.input-file-upload {
        .file-upload {
          padding: 0 !important;
          background: #fff;

          .ui.button {
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }

    &.breadcrumb a {
      color: ${payStarColors.primary.lightBlue};
    }

    &.table {
      font-size: 1rem;

      .error-text {
        color: ${payStarColors.errorText} !important;
      }

      .muted-text {
        color: ${payStarColors.mutedText} !important;
      }

      .warning-text {
        color: ${payStarColors.warningText} !important;
      }

      &.compact th {
        padding: 0.528571em 0.78571429em;
      }

      &.table tr th {
        border-left: none;
      }

      &.celled tr th {
        border-left: 1px solid rgba(222, 222, 222, 0.43);
      }

      thead th {
        border-radius: 0 !important;
        color: ${payStarColors.blue1};
        font-weight: 500;
      }

      &.selectable tbody tr {
        cursor: pointer;

        &.active,
        &.active:hover,
        &:hover {
          background: #d3eafb !important;

          .ui.label {
            /* border: solid 1px; */
          }
        }
      }

      tbody tr,
      thead tr {
        .hidden-row-action {
          opacity: 0;
        }

        &:hover .hidden-row-action {
          opacity: 1;
        }

        td:first-of-type,
        th:first-of-type {
          padding-left: 30px;
        }

        td:last-child,
        th:last-child {
          padding-right: 30px;
        }
      }

      &[class*='very basic']&:not(.not-padded) td:last-child {
        padding-right: 0.78571429rem !important;
      }
    }

    &.button {
      font-weight: normal;

      &.primary {
        background-color: ${payStarColors.primary.blue};

        &:hover,
        &:focus {
          background-color: ${lighten(0.075, payStarColors.primary.blue)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.secondary {
        background-color: #e8e8e8;
        color: ${payStarColors.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover,
        &:focus {
          color: ${payStarColors.textLabel};
          background-color: ${darken(0.075, '#e8e8e8')} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.tertiary {
        background-color: #fff;
        color: ${payStarColors.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;

        &.negative {
          color: #db2828;

          &:hover,
          &:focus,
          &:active {
            background: #f9dbdc !important;
            color: #db2828;
          }
        }

        &.primary {
          background-color: #fff;
          color: ${payStarColors.primary.blue};

          &:hover,
          &:focus,
          &:active {
            background: ${transparentize(0.8, payStarColors.primary.blue)} !important;
            color: ${darken(0.1, payStarColors.primary.blue)};
          }
        }

        &:hover,
        &:focus {
          color: ${payStarColors.textLabel};
          background-color: ${darken(0.075, '#e8e8e8')} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.basic {
        background-color: ${payStarColors.white1} !important;
        color: ${payStarColors.black};

        &:not(.action-button) {
          box-shadow: 0px 0px 0px 1px ${payStarColors.basicBoxShadow} !important;
        }
        &:hover {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${darken(0.08, payStarColors.white1)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${darken(0.02, payStarColors.white1)} !important;
        }
        &:active {
          box-shadow: 0px 0px 0px 1px ${darken(0.08, payStarColors.basicBoxShadow)};
          color: ${darken(0.08, payStarColors.black)} !important;
          background-color: ${darken(0.05, payStarColors.white1)} !important;
        }

        &.transparent {
          background-color: ${payStarColors.transparent} !important;
          &:hover {
            background-color: ${payStarColors.transparentHover} !important;
          }
          &:active {
            background-color: ${darken(0.05, payStarColors.transparentHover)} !important;
          }
        }

        &.warning {
          background-color: ${payStarColors.warningBackground} !important;
          box-shadow: 0px 0px 0px 1px ${payStarColors.warningBoxShadow} !important;
          color: ${payStarColors.warning};
          &:hover {
            box-shadow: 0px 0px 0px 1px ${darken(0.2, payStarColors.warningBoxShadow)};
            color: ${darken(0.2, payStarColors.warning)} !important;
            background-color: ${darken(0.03, payStarColors.warningBackground)} !important;
          }
          &:focus {
            box-shadow: 0px 0px 0px 1px ${darken(0.2, payStarColors.warningBoxShadow)};
            color: ${darken(0.2, payStarColors.warning)} !important;
            background-color: ${darken(0.03, payStarColors.warningBackground)} !important;
          }
          &:active {
            box-shadow: 0px 0px 0px 1px ${darken(0.3, payStarColors.warningBoxShadow)};
            color: ${darken(0.3, payStarColors.warning)} !important;
            background-color: ${darken(0.05, payStarColors.warningBackground)} !important;
          }
        }

        &.clear {
          background-color: transparent !important;
          box-shadow: none !important;

          &:hover,
          &:focus {
            background-color: rgba(27, 28, 29, 0.1) !important;
          }
        }
      }

      &.link {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        display: inline-block;
        padding: 5px 0px;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${darken(0.02, payStarColors.white1)} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.white {
        background: #fff !important;
        box-shadow: 0px 0px 0px 1px rgba(107, 112, 117, 0.35) inset,
          0px 0px 0px 0px rgba(50, 53, 56, 0.15) inset;
      }

      &.clear {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${transparentize(0.9, payStarColors.black)} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.no-focus:not(:active) {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &.header {
      &.no-margin {
        margin: 0;
      }

      &.small {
        font-weight: 500;
        font-size: 0.971429rem;
      }

      &.inverted.attached {
        background-color: ${payStarColors.primary.blue};
      }

      &.icon svg {
        display: block;
        line-height: 1;
        padding: 0em;
        font-size: 3em;
      }

      &.icon > svg {
        margin: 0em auto 0.5rem;
      }
    }

    &.buttons > .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) > .button,
    &.buttons .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) .button {
      box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    }

    &.menu {
      &.pagination {
        border: 1px solid ${payStarColors.basicBoxShadow};
        box-shadow: none;

        .item:before {
          background: ${payStarColors.basicBoxShadow};
        }
      }

      &.secondary.pointing .active.item {
        font-weight: normal;
        color: ${payStarColors.primary.blue};
        border-color: ${payStarColors.primary.blue};
      }
    }

    &.page.modals {
      z-index: 1000;
    }

    &.modal {
      &.basic {
        .ui.segment {
          color: #0a2233;
        }

        > .close {
          padding: 0.6rem 0rem 0rem 0.1rem;
          border: solid 2px #fff;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.88);
          background: #000;
          color: #fff;
          border-radius: 4rem;
          font-size: 0.9rem;
          top: 0.5rem;
          right: 0.3rem;
          opacity: 1;
        }
      }

      .close-icon {
        position: absolute;
        right: -1rem;
        top: -1rem;

        .ui.button {
          width: 2rem;
          height: 2rem;
          padding: 0;
          border: solid 2px #fff;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.88);
        }
      }

      .content .actions {
        margin: 15px -1.5rem -1.5rem;
        padding: 15px 10px;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.02);
      }

      & > .actions {
        text-align: left;
      }

      .ui.form .form-actions {
        margin: 2em -1em -1em !important;
        padding: 1.5em 1em 1.5em 1em !important;
      }

      .ui.dimmer:not(.active) + .ui.loader {
        display: none;
      }
    }

    &.segment {
      display: block;
      border: 1px solid rgba(34, 36, 38, 0.3);

      padding: 2em;
      @media only screen and (max-width: 767px) {
        padding: 1em;
      }

      &.padding-sm {
        padding: 1em;
      }

      &.placeholder {
        min-height: 8rem;
        display: flex;
      }
    }

    &.tab {
      &.basic.segment {
        padding: 0;
      }
    }

    &.statistic {
      .label {
        text-align: left;
        font-weight: normal;
      }
    }

    &.dimmer {
      background-color: rgba(0, 0, 0, 0.75);
    }

    a.hint {
      float: right;
      font-weight: normal;
      font-size: 1rem;
    }
  }

  .secondary-navigation {
    background-color: #fff;
    padding: 6px 0;
    border-bottom: 2px solid #d9d9da;

    .ui.container {
      max-width: 1330px !important;

      @media only screen and (max-width: 767px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .ui.secondary.pointing.menu {
      border-bottom: 2px solid #d9d9da;
      margin-bottom: -8px;
    }
  }

  .muted,
  .muted label {
    color: ${payStarColors.mutedText} !important;
  }

  .text-bold {
    font-weight: 600;
  }

  .relative {
    position: relative !important;
  }

  .clearfix:after {
    content: '';
    display: table;
    clear: both;
  }

  .react-toast-notifications__container {
    z-index: 10000 !important;
  }

  [data-reach-skip-link]:focus {
    z-index: 10;
  }

  [data-reach-tab-list] {
    margin: 0 0 1em 0;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    padding: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  }

  [data-reach-tab-panel] {
    outline: none;
  }

  [data-reach-tab] {
    display: inline-block;
    padding: 0.25em 0.5em;
    margin: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-self: flex-end;
    border: none;
    margin: 0 2.5px 0;
    padding: 0.557143em 1.14285714em;
    border-bottom-width: 2px;
    transition: color 0.1s ease;
    background-color: transparent;
    color: #0a2233;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &[data-selected] {
      background: #185885;
      color: #fff;
    }

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  [data-reach-tab]:disabled {
    opacity: 0.25;
    cursor: default;
  }

  [data-reach-menu-list],
  [data-reach-menu-items] {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0.26666667rem;
    margin-top: 0.33333333em;
    padding: 0;
  }

  [data-reach-menu-item][data-selected] {
    background: rgba(0, 0, 0, 0.05) !important;
    color: rgba(0, 0, 0, 0.95) !important;
  }

  [data-reach-menu-button][aria-expanded='true'] {
    background: rgba(20, 74, 111, 0.2) !important;
    color: #0c2d44;
  }
`;

export const GlobalStyles = () => <Global styles={globalStyles} />;

const DEFAULT_RESOLVE_THEME = (): Promise<Theme> => Promise.resolve(defaultTheme);

export const ThemeProvider = ({children, resolveTheme}: ThemeProvider) => {
  const fetchTheme = useAsync(resolveTheme);

  if (fetchTheme.loading) {
    return (
      <DelayedLoadingContainer delayInMs={500}>
        <Loader inline="centered" active>
          Loading...
        </Loader>
      </DelayedLoadingContainer>
    );
  }

  if (fetchTheme.error || !fetchTheme.value) {
    return <div>Error</div>;
  }

  return (
    <EmotionThemeProvider theme={fetchTheme.value}>
      <ThemeContainer className="root">{children}</ThemeContainer>
    </EmotionThemeProvider>
  );
};

ThemeProvider.defaultProps = {
  resolveTheme: DEFAULT_RESOLVE_THEME,
};
