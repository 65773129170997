import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {Env} from '../config/env-vars';

type LogFunction = (message: string, ...optionalParams: any[]) => void;

type Logger = {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
};

type levels = 'debug' | 'info' | 'warn' | 'error';

const CoreLogger = {} as any as Logger;

['debug', 'info', 'warn', 'error'].forEach((level) => {
  CoreLogger[level] = console[level] || console.log;
});

const createLogger = (namespace, subject, level: levels) => {
  return (message: string, ...optionalParams: any[]) => {
    const log = CoreLogger[level];
    const data = (optionalParams || [])[0] || {};

    switch (level) {
      case 'info':
        Sentry.addBreadcrumb({level: Severity.Info, message, data});
        break;
      case 'warn':
        Sentry.addBreadcrumb({level: Severity.Warning, message, data});
        break;
      case 'error':
        Sentry.addBreadcrumb({level: Severity.Error, message, data});
        Sentry.captureException(message);
        break;

      default:
        break;
    }

    if (!logEnabled) return;

    log.call(CoreLogger, `${namespace}:${subject}:${level} => ${message}`, ...optionalParams);
  };
};

const DEFAULT_NAMESPACE = 'paystar';

export const logger: (namespace, config?) => Logger = (
  subject,
  config = {
    namespace: DEFAULT_NAMESPACE,
  }
) => {
  const namespace = config.namespace || DEFAULT_NAMESPACE;
  return {
    debug: createLogger(namespace, subject, 'debug'),
    info: createLogger(namespace, subject, 'info'),
    warn: createLogger(namespace, subject, 'warn'),
    error: createLogger(namespace, subject, 'error'),
  };
};

let logEnabled: string | null = null;
try {
  logEnabled = localStorage.getItem('debug');
} catch (error) {}

const developmentLogger = logger('development');

if (!Env.isProductionBuild) {
  try {
    localStorage.setItem('debug', 'true');
  } catch (error) {}
} else {
  ['debug', 'info', 'warn', 'error'].forEach((x) => {
    console[x] = developmentLogger[x];
  });
}

export {developmentLogger as debug};
