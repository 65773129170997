import React from 'react';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {AnyObject} from '../../types';

export type DropdownAdapterProps = DropdownProps & {
  enum?: AnyObject;
};

export const DropdownAdapter = ({input, meta, ...rest}) => {
  const {options, enum: enumObject, ...dropdownProps} = rest as DropdownAdapterProps;
  const _options = enumObject ? getEnumDropdownOptions(enumObject) : options;

  return (
    <Dropdown
      selection={true}
      {...input}
      {...dropdownProps}
      options={_options}
      onChange={(event, {value}) => input.onChange(value)}
    />
  );
};
