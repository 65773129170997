import React from 'react';
import {useFormState} from 'react-final-form';
import {Json} from '../components/json';

type Debug = {
  full?: boolean;
  defaultOpen?: boolean;
};

export const Debug: React.FC<Debug> = ({full = false, defaultOpen}) => {
  const {values, ...rest} = useFormState();
  if (full) {
    return <Json data={{values, ...rest}} defaultOpen={defaultOpen} />;
  }
  return <Json data={values} defaultOpen={defaultOpen} />;
};
