import React from 'react';
import {Header} from 'semantic-ui-react';
import {css} from '@emotion/react/macro';

const styles = css`
  &.ui.header {
    padding-top: 15px;
  }
`;

export const FormSection = ({title, children}) => (
  <>
    <Header size="small" className="form-section" css={styles}>
      {title}
    </Header>
    {children}
  </>
);
