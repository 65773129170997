import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {Env} from '../config/env-vars';

function guard<ReturnType, DefaultValue = ReturnType>(
  fn: () => ReturnType | DefaultValue,
  defaultValue: ReturnType
): () => ReturnType | DefaultValue;
function guard<InputParam, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (param1: InputParam) => ReturnType | DefaultValue;
function guard<InputParam1, InputParam2, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam1, param2: InputParam2) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (param1: InputParam1, param2: InputParam2) => ReturnType | DefaultValue;
function guard<InputParam1, InputParam2, InputParam3, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam1, param2: InputParam2, param3: InputParam3) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (param1: InputParam1, param2: InputParam2, param3: InputParam3) => ReturnType | DefaultValue {
  const guarded: typeof fn = (...args) => {
    try {
      return fn(...args);
    } catch (error: any) {
      return handleError(error, defaultValue, args);
    }
  };

  return guarded;
}

function guardAsync<ReturnType, DefaultValue = ReturnType>(
  fn: () => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): () => Promise<ReturnType | DefaultValue>;
function guardAsync<InputParam, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (param1: InputParam) => Promise<ReturnType | DefaultValue>;
function guardAsync<InputParam1, InputParam2, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam1, param2: InputParam2) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (param1: InputParam1, param2: InputParam2) => Promise<ReturnType | DefaultValue>;
function guardAsync<InputParam1, InputParam2, InputParam3, ReturnType, DefaultValue = ReturnType>(
  fn: (
    param1: InputParam1,
    param2: InputParam2,
    param3: InputParam3
  ) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (
  param1: InputParam1,
  param2: InputParam2,
  param3: InputParam3
) => Promise<ReturnType | DefaultValue> {
  const guarded: typeof fn = async (...args) => {
    try {
      return await fn(...args);
    } catch (error: any) {
      return handleError(error, defaultValue, args);
    }
  };

  return guarded;
}

function handleError<T>(error: Error, defaultValue: T, args?: any[]): T {
  if (Env.isProductionBuild) {
    Sentry.addBreadcrumb({
      level: Severity.Warning,
      message: error.message,
      data: {args},
    });

    Sentry.captureException(error);
  }
  return defaultValue;
}

export {guard, guardAsync};
