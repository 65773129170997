import React from 'react';
import {Checkbox, StrictCheckboxProps} from 'semantic-ui-react';

export type CheckboxAdapterProps = StrictCheckboxProps & {
  option?: string;
};

export const CheckboxAdapter = ({input, meta, ...rest}) => {
  const {value, ...inputValues} = input;
  const {option, ...passedProps} = rest;

  if (rest.radio && !option) {
    throw new Error('option value is required for radio buttons');
  }

  const id = rest.radio ? `${rest.id}_${rest.option}` : rest.id;

  return (
    <Checkbox
      {...inputValues}
      {...passedProps}
      id={id}
      name={id}
      checked={rest.radio ? value === option : !!value}
      type={rest.radio ? 'radio' : 'checkbox'}
      onChange={(event, {checked}) => {
        input.onChange(rest.radio ? option : checked);
      }}
      label={passedProps.label}
    />
  );
};
