import React from 'react';
import {cx} from '@emotion/css';
import {css} from '@emotion/react';

export const Flex = ({children}: {children: React.ReactNode}) => (
  <div css={styles} className="flex-container">
    {children}
  </div>
);

type FlexProps = {
  children: any;
  className?: string;
  container?: boolean;
  fill?: boolean;
};

type RowProps = FlexProps & {
  align?: 'top' | 'center' | 'bottom';
  vAlign?: 'top' | 'center' | 'bottom';
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'justify-center';
};

type ColProps = FlexProps & {
  align?: 'left' | 'center' | 'right';
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'justify-center';
};

const Row: React.FC<RowProps> = (props) => {
  const className = cx('row', props.className, {
    container: props.container,
    [props.align as string]: !!props.align,
    [props.vAlign as string]: !!props.vAlign,
    [props.justifyContent as string]: !!props.justifyContent,
    fill: !!props.fill,
  });
  const {children} = props;

  return (
    <div css={styles} className={className}>
      {children}
    </div>
  );
};

const Col: React.FC<ColProps> = (props) => {
  const className = cx('column', props.className, {
    [props.align as string]: !!props.align,
    [props.justifyContent as string]: !!props.justifyContent,
    fill: !!props.fill,
  });
  const {children} = props;

  return (
    <div css={styles} className={className}>
      {children}
    </div>
  );
};

const Fill: React.FC<{className?: string; scroll?: boolean}> = ({children, className, scroll}) => (
  <div className={cx('fill', className, {scroll})}>{children}</div>
);
const Box: React.FC<{className?: string}> = ({children, className}) => (
  <div className={cx('box', className)}>{children}</div>
);

Flex.Row = Row;
Flex.Col = Col;
Flex.Fill = Fill;
Flex.Box = Box;

type FlexRowWithActions = {
  actions: React.ReactNode;
  actionsPosition?: 'left' | 'right';
  align?: RowProps['align'];
  className?: string;
};
export const FlexRowWithActions: React.FC<FlexRowWithActions> = ({
  actions,
  actionsPosition = 'left',
  align = 'center',
  className,
  children,
}) => (
  <Flex.Row align={align} className={className}>
    {actionsPosition === 'left' ? (
      <>
        <Flex.Box>{actions}</Flex.Box>
        <Flex.Fill>{children}</Flex.Fill>
      </>
    ) : (
      <>
        <Flex.Fill>{children}</Flex.Fill>
        <Flex.Box>{actions}</Flex.Box>
      </>
    )}
  </Flex.Row>
);

const styles = css`
  &.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &.row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 767px) {
      &:not(.unstackable) {
        display: block;

        > * {
          margin-bottom: 1rem;
        }
      }
    }

    &.top {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.space-around {
      justify-content: space-around;
    }

    &.space-evenly {
      justify-content: space-evenly;
    }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.justify-center {
      justify-content: center;
    }
  }

  &.column {
    display: flex;
    flex-direction: column;

    &.center {
      align-items: center;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.space-around {
      justify-content: space-around;
    }

    &.space-evenly {
      justify-content: space-evenly;
    }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.justify-center {
      justify-content: center;
    }
  }

  .fill {
    flex: 1 1 0%;
  }

  .scroll {
    overflow-y: auto;
  }
`;
