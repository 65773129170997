import React from 'react';
import {Env} from './config/env-vars';

// import ReactDOM from 'react-dom';


if (!Env.isProductionBuild) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    //trackAllPureComponents: true,
  });

  // const axe = require('@axe-core/react').default;
  // axe(React, ReactDOM, 1000);
}
