import {nanoid} from 'nanoid';
import React, {createContext, useContext, useEffect} from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import {useMatch} from '@reach/router';
import {ClientQueryKeys, Roles} from '../api/generated/enums';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import {Env} from '../config/env-vars';
import {actionCreatorFactory, createReducer, useImmerReducer} from '../hooks/use-immer-reducer';
import {useMemoizedValue} from '../hooks/use-memoized-value';
import {useQueryParams} from '../hooks/use-query-params';
import {subscribeGlobal} from '../hooks/use-subscription';
import {routes} from '../routes';
import {AnyObject, User} from '../types';
import {logger} from '../utils/logger';
import {getUser, initializeUserManager, mapOidcUser, userManager} from '.';

const authLogger = logger('auth');

type AuthState = {
  user: User | null;
  pending: boolean;
  error: Error | null;
  redirectUrl?: string;
};

type OidcUser = Parameters<typeof mapOidcUser>[0];

const action = actionCreatorFactory<AuthState>();
const onUserLoaded = action<OidcUser>('ON_USER_LOADED');
const onUserUnloaded = action('ON_USER_UNLOADED');
const redirecting = action('REDIRECTING');

const reducer = createReducer<AuthState>()
  .when(onUserLoaded, (draft, user) => {
    draft.pending = false;
    draft.user = mapOidcUser(user);
    const {url} = user.state || {};

    if (url) {
      draft.redirectUrl = url;
    }
  })
  .when(onUserUnloaded, (draft) => {
    draft.pending = true;
    draft.user = null;
  })
  .when(redirecting, (draft) => {
    draft.pending = true;
    draft.user = null;
  });

const INITIAL_STATE: AuthState = {
  user: null,
  pending: true,
  error: null,
};

export const AuthContext = createContext<AuthState>(INITIAL_STATE);

const AuthProvider = (props: any) => {
  const [state_, dispatch] = useImmerReducer(reducer, INITIAL_STATE);
  const state = useMemoizedValue(state_);

  let businessUnitSlug = '';
  var customerSlug = useMatch(`${routes.customer.base}`)?.slug;
  var cp_login_mode = useQueryParams<AnyObject>().cp_login_mode;
  var accountNumber = useQueryParams<AnyObject>().accountNumber;

  if (customerSlug) {
    businessUnitSlug = customerSlug || '';
  }

  useEffect(() => {
    const extraQueryParams: AnyObject = {
      [ClientQueryKeys.BusinessUnitSlug]: businessUnitSlug,
      [ClientQueryKeys.AuthSessionToken]: nanoid(),
    };

    if (cp_login_mode) {
      extraQueryParams.cp_login_mode = cp_login_mode;
    }

    if (accountNumber) {
      extraQueryParams[ClientQueryKeys.accountNumber] = accountNumber;
    }

    initializeUserManager(extraQueryParams);

    (async () => {
      const user = await getUser();
      authLogger.debug('Initial user', user);

      userManager.events.addUserLoaded(async (updatedUser) => {
        dispatch(onUserLoaded(updatedUser));
      });

      userManager.events.addUserUnloaded(() => {
        dispatch(onUserUnloaded(undefined));
      });

      if (user && !user.expired) {
        dispatch(onUserLoaded(user));
      } else if (window.location.href.includes('#id_token')) {
        authLogger.debug('Handle callback');
        try {
          await userManager.signinRedirectCallback();
        } catch (error) {
          authLogger.info('Callback Error', error);
          (window as any).location = Env.appRoot;
        }
      } else {
        authLogger.info('signinRedirect');
        dispatch(redirecting(undefined));

        let pathname = window.location.pathname;
        let search = window.location.search;

        userManager.signinRedirect({
          state: {
            url: pathname + search,
          },
        });
      }
    })();
  }, [businessUnitSlug, cp_login_mode, accountNumber, dispatch]);

  if (state.pending) {
    return (
      <DelayedLoadingContainer delayInMs={1000}>
        <Dimmer active inverted>
          <Loader indeterminate />
        </Dimmer>
      </DelayedLoadingContainer>
    );
  }

  if (!state.user && !state.pending) {
    return <div>Not Authenticated</div>;
  }

  return <AuthContext.Provider value={state} {...props} />;
};

let adminMockRole = '';

if (!Env.isProductionBuild) {
  subscribeGlobal('admin-mock-role', (event) => {
    adminMockRole = event.role;
  });
}

function useUser(): User {
  const {user} = useContext(AuthContext);
  if (!user) {
    throw new Error(`useUser must be used within an authenticated app`);
  }

  if (
    !Env.isProductionBuild &&
    user.role === 'Global Admin' &&
    adminMockRole &&
    Roles[adminMockRole]
  ) {
    return {...user, role: adminMockRole as any};
  }

  return user;
}

export {AuthProvider, useUser, authLogger, adminMockRole};
