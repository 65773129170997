import React, {useCallback, useMemo} from 'react';
import {css} from '@emotion/react/macro';
import {
  Form as FinalForm,
  FormProps,
  FormRenderProps,
  useFormState,
  AnyObject,
} from 'react-final-form';
import {FormApi} from 'final-form';
import {Form as SemanticForm} from 'semantic-ui-react';
import {difference, get, isString} from 'lodash';
import {cx} from '@emotion/css';
import {textInputs} from 'polished';

import {mapErrors, getNestedKeys} from './map-formal-errors';
import {ApiResponse} from '../types';
import {Alert} from '../components/alert';
import {FormStateProvider} from './state-context';
import {payStarColors} from '../styles/styled';
import {Media} from '../styles/breakpoints';

type FinalFormProps<T = AnyObject> = FormProps<T> & {
  disabled?: boolean;
  disableLoading?: boolean;
  className?: string;
  autoComplete?: 'off';
};

type NonFieldErrors<T = AnyObject> = {
  form: FormApi<T>;
  submitErrors?: object;
};

export function Form<T extends object = AnyObject>(props: FinalFormProps<T>) {
  const {
    className,
    children,
    render,
    onSubmit,
    disabled = false,
    disableLoading = false,
    autoComplete = undefined,
    ...finalFormProps
  } = props;
  const renderFn = (children || render) as (props: FormRenderProps<T>) => React.ReactNode;

  const _onSubmit = useCallback(
    async (values, form, callback) => {
      const response = await onSubmit(values, form, callback);

      if (response && response.hasErrors) {
        const fields = form.getRegisteredFields();
        return mapErrors(response as ApiResponse<any>, fields);
      }

      return response;
    },
    [onSubmit]
  );

  return (
    <FormStateProvider initialState={{disabled}}>
      <FinalForm<T>
        onSubmit={_onSubmit}
        {...finalFormProps}
        render={(formRenderProps) => {
          const {handleSubmit, submitErrors, hasSubmitErrors, submitting, form} = formRenderProps;

          return (
            <SemanticForm
              onSubmit={handleSubmit}
              error={hasSubmitErrors}
              loading={!disableLoading && submitting}
              className={cx(className, {disabled})}
              css={styles}
              autoComplete={autoComplete}
              {...(!!finalFormProps.id ? {id: finalFormProps.id} : {})}
            >
              <NonFieldErrors<T> {...{form, submitErrors}} />
              {renderFn(formRenderProps)}
            </SemanticForm>
          );
        }}
      />
    </FormStateProvider>
  );
}

const ErrorMessage = ({name}) => {
  const formState = useFormState();
  const errors = formState.submitErrors || {};
  if (errors[name] && isString(errors[name])) {
    return <Alert negative>{errors[name]}</Alert>;
  }
  return null;
};

const ErrorNotice = () => {
  const {hasSubmitErrors} = useFormState();

  return hasSubmitErrors ? (
    <div className="sui-error-message">
      <Alert negative>There was a problem. Check fields for errors.</Alert>
    </div>
  ) : null;
};

Form.ErrorMessage = ErrorMessage;
Form.ErrorNotice = ErrorNotice;

function NonFieldErrors<T = AnyObject>({form, submitErrors}: NonFieldErrors<T>) {
  const nonFieldErrors = useMemo(() => {
    const fields = form.getRegisteredFields();

    const errorKeys = getNestedKeys(submitErrors);
    return difference(errorKeys, fields);
  }, [form, submitErrors]);

  if (!nonFieldErrors.length || !submitErrors) {
    return null;
  }

  return (
    <Alert negative>
      <ul>
        {nonFieldErrors.map((errorKey) => (
          <li key={errorKey}>{get(submitErrors, errorKey)}</li>
        ))}
      </ul>
    </Alert>
  );
}

const inlineConfig = {
  label: 150,
  paddingHorizontal: 15,
  inputs: {
    default: 500,
    short: 250,
  },
};

const inlineInputOffset = inlineConfig.label + inlineConfig.paddingHorizontal;

const fullWidthFields = css`
  .form-row {
    display: block;

    .form-field {
      flex: 1 1 0%;
      margin: 0 0 1rem 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .form-field {
    width: 100%;
  }
`;

const styles = css`
  &.ui.form {
    .ui.small.header {
      font-weight: 500;
      font-size: 0.971429em;
    }

    .form-row {
      width: 100%;
      display: flex;

      .form-field {
        flex: 1 1 0%;
        margin: 0 0 1rem 0;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    .form-field {
      clear: both;
      margin: 0em 0em 1em;

      &.hidden {
        display: none;
      }
    }

    .sui-error-message {
      display: block;
      color: rgb(159, 58, 56);
      font-size: 0.92857143em;
      white-space: normal;
    }

    .error.field + .sui-error-message {
      margin-top: -0.9em;
    }

    .form-field-hint {
      display: block;
      margin: -0.9rem 0 0 0;
      font-size: 0.92857143rem;
      color: #666;
      max-width: 100%;
    }

    ${Media('MobileMax')} {
      ${fullWidthFields};

      .form-actions {
        .ui.button {
          display: block !important;
          width: 100%;

          + .ui.button {
            margin-top: 1rem;
          }

          &.clear {
            box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) !important;
          }
        }
      }
    }

    ${Media('TabletMin')} {
      &.inline {
        .ui.header.form-section {
          margin: 0.5em 0 1em;
          padding: 1em 0.5em 0.5em;
          border-bottom: solid 1px #dededf;

          &:first-of-type {
            margin-top: -1em;
          }
        }

        .form-actions {
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 2em -2em -2em;
          padding: 1.5em 2em 1.5em 2em;
          border-top: solid 1px #dededf;
          border-radius: 0.28571429rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background: #fff;

          .form-actions-text {
            flex: 0 0 100%;
          }

          .ui.button.primary {
            min-width: ${inlineInputOffset}px;
          }
        }

        .field-divider {
          margin-left: ${inlineInputOffset}px;
        }

        .split-column {
          position: relative;
          display: flex;
          margin-bottom: 1em;

          > div {
            flex: 1;
            margin-bottom: 0px;
          }
        }

        .form-field {
          clear: both;
          margin: 0em 0em 1em;
        }

        .field {
          display: flex;
          align-items: flex-start;

          > label:first-of-type {
            margin: 0em 15px 0em 0em;
          }

          > label {
            flex-shrink: 0;
            width: ${inlineConfig.label}px;
            padding-top: calc(0.67857143em + 2px);

            text-align: right;
            font-weight: normal;
            display: inline-block;
            margin-top: 0em;
            margin-bottom: 0em;
            vertical-align: baseline;
            font-size: 0.92857143em;

            &:after {
              content: ':';
            }
          }

          > span.no-label {
            flex-shrink: 0;
            width: ${inlineConfig.label}px;
            padding-top: calc(0.67857143em + 2px);

            margin: 0em 15px 0em 0em;
          }

          > .ui.input,
          > .ui.button,
          > .markdown-editor,
          > textarea,
          .ui.dropdown:not(.label) {
            max-width: ${inlineConfig.inputs.default}px;
            vertical-align: middle;
            border-width: 2px;
            border-color: ${payStarColors.grey2};
            width: 100%;

            &.input-currency > input {
              padding-left: 2.1em !important;
            }

            &.short {
              width: ${inlineConfig.inputs.short}px;
            }
          }

          .ui.radio.checkbox {
            display: block !important;
            padding: 0.35857143em 0em;

            &:first-of-type {
              padding-top: calc(0.67857143em + 2px);
            }
          }

          .ui.fitted.toggle.checkbox {
            padding-top: calc(0.67857143em);
          }
        }

        .form-field-hint {
          display: block;
          margin-left: ${inlineInputOffset}px;

          font-size: 0.92857143em;
          color: #666;
          max-width: ${inlineConfig.inputs.default}px;
        }

        .sui-error-message {
          display: block;
          margin: 0 0 1em ${inlineInputOffset}px;

          color: rgb(159, 58, 56);
          font-size: 0.92857143em;
        }
      }

      .form-actions {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 2em -2em -2em;
        padding: 1.5em 2em 1.5em 2em;
        border-top: solid 1px #dededf;
        border-radius: 0.28571429rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background: #fff;

        .form-actions-text {
          flex: 0 0 100%;
        }

        .ui.button.primary {
          min-width: ${inlineInputOffset}px;
        }
      }
    }

    ${Media('DesktopMin')} {
      .admin-portal-styles & {
        &.inline {
          .field {
            > label {
              width: ${inlineConfig.label + 150}px;
            }

            > span.no-label {
              width: ${inlineConfig.label + 150}px;
            }
          }

          .sui-error-message {
            margin-left: ${inlineInputOffset + 150}px;
          }

          .form-field-hint {
            margin-left: ${inlineInputOffset + 150}px;
          }
        }
      }
    }

    &.strong-labels {
      .field > label,
      .field-label {
        font-weight: bold;
      }
    }

    &.disabled {
      .form-actions {
        display: none;
      }

      .disabled.field {
        opacity: 1;

        > label {
          opacity: 1;
        }
      }

      ${textInputs()} {
        background: #f1f1f1;
        color: #6b6b6b;
        &:focus {
          outline: none;
          background: #f1f1f1;
          color: #6b6b6b;
        }

        .dropdown,
        .selection.dropdown {
          background: #f1f1f1;
          color: #6b6b6b;
          &:focus {
            outline: none;
            background: #f1f1f1;
            color: #6b6b6b;
          }
        }
      }
    }
  }
`;
