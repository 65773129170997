/* eslint-disable */

import {
  PaymentReceiptDto,
  Currency,
  PaymentSummaryDto,
  PaymentSourceJsonDto,
  AccountSummaryDto,
  OptionalAddress,
  AccountFieldGetDto,
  PaymentSessionLineItemGetDto,
  PaymentFieldItemDto,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ReceiptsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getReceipt(
    params: {
      /**  */
      referenceNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentReceiptDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/{referenceNumber}/receipt';
      url = url.replace('{referenceNumber}', params['referenceNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
