import React from 'react';
import {useAsync, useWindowSize} from 'react-use';
import {RouteComponentProps} from '@reach/router';
import ReactPDF, {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {ReceiptsService} from '../api/generated/ReceiptsService';
import {PaymentLineItemTypes, PaymentSourceTypes, PaymentStatuses} from '../api/generated/enums';
import {logoUrl} from '../assets/paystar-logo-base64';
import {AsyncStateContainer} from '../components/async-state-container';
import Currency from '../components/currency';
import {DateFormat} from '../components/date';
import {RouteParams} from '../routes';
import {Barcode} from './payment-receipt-barcode';
export const Receipt = (props: RouteComponentProps) => {
  const {referenceNumber} = props as RouteParams<{referenceNumber: string}>;
  const {width, height} = useWindowSize();
  const fetchingReceipt = useAsync(async () => {
    return await ReceiptsService.getReceipt({referenceNumber});
  });

  const {
    basePayment,
    serviceFee,
    authCode,
    lineItems = [],
    paymentFieldItems = [],
    accounts = [],
    hasAdjustments,
    refundAmount,
    businessUnitFeaturesPaymentReceiptBarcodes,
  } = fetchingReceipt.value?.data || {};

  const paymentSourceLabel =
    basePayment?.paymentSource.paymentSourceType === PaymentSourceTypes['Credit Card']
      ? 'Card #:'
      : 'Account Number:';

  const includedFee = lineItems.find((x) => x.lineItemType === PaymentLineItemTypes.ServiceFee);
  const feeAmount = serviceFee ? serviceFee.amountMinor : includedFee?.price.value;

  return (
    <AsyncStateContainer {...fetchingReceipt}>
      {basePayment && (
        <PDFViewer {...{height, width}}>
          <Document>
            <Page style={styles.page} size={[195, 622]}>
              <View>
                <ChunkText text={basePayment.header} maxLength={25} style={styles.title} />
                <ChunkText text={basePayment.subHeader} maxLength={25} style={styles.subTitle} />
              </View>

              <View style={styles.content}>
                <View style={styles.row}>
                  <Text style={styles.label}>Date:</Text>
                  <Text style={styles.value}>
                    <DateFormat date={basePayment.createdDate} format="MM/DD/YYYY hh:mm A" />
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>Reference #:</Text>
                  <Text style={styles.value}>{basePayment.referenceNumber}</Text>
                </View>

                {(paymentFieldItems || []).map((x) => (
                  <View key={x.key} style={styles.row}>
                    <Text style={styles.label}>{x.label}:</Text>
                    <ChunkText text={x.value} maxLength={25} style={styles.value} />
                  </View>
                ))}

                <Text style={styles.section} />

                {accounts.map((account) => {
                  // const accountMeta = metaAccounts.find((x) => x.Id === account.id);

                  const subaccount = account.subAccountNumber
                    ? ` / ${account.subAccountNumber}`
                    : '';
                  const accountDisplay = `${account.accountNumber}${subaccount}`;
                  const accountTotal = lineItems
                    .filter((x) => x.accountId === account.id)
                    .reduce((acc, item) => acc + item.price.value, 0);

                  return (
                    <View key={account.id} style={styles.accountContainer}>
                      {!!account.name && (
                        <View style={styles.row}>
                          <Text style={styles.wideLabel}>{account.name}</Text>
                        </View>
                      )}
                      <View style={styles.row}>
                        <Text style={styles.wideLabel}>{accountDisplay}</Text>
                        <Text style={styles.value}>
                          <Currency amount={accountTotal} />
                        </Text>
                      </View>
                    </View>
                  );
                })}

                {lineItems
                  .filter((x) => !x.accountId && x.lineItemType !== PaymentLineItemTypes.ServiceFee)
                  .map((x) => (
                    <View key={`line-item_${x.id}`} style={styles.lineItem}>
                      <Text style={styles.wideLabel}>{x.description}:</Text>
                      <Text style={styles.value}>
                        <Currency amount={x.price.value} />
                      </Text>
                    </View>
                  ))}

                <View style={styles.paymentTotalRow}>
                  <View style={styles.row}>
                    <Text style={styles.wideLabel}>Payment Total:</Text>
                    <Text style={styles.value}>
                      <Currency amount={basePayment.amount.value} />
                    </Text>
                  </View>
                </View>

                {refundAmount && (
                  <View style={styles.paymentTotalRow}>
                    <View style={styles.row}>
                      <Text style={styles.wideLabel}>Refund Amount:</Text>
                      <Text style={styles.value}>
                        <Currency amount={refundAmount.value} />
                      </Text>
                    </View>
                  </View>
                )}

                {feeAmount &&
                  (!!includedFee ? (
                    <View style={styles.row}>
                      <Text style={styles.wideLabel}>Service Fee (incl):</Text>
                      <Text style={styles.value}>
                        <Currency amount={feeAmount} />
                      </Text>
                    </View>
                  ) : basePayment.status !== PaymentStatuses.Refunded ? (
                    <View style={styles.row}>
                      <Text style={styles.wideLabel}>Service Fee:</Text>
                      <Text style={styles.value}>
                        <Currency amount={feeAmount} />
                      </Text>
                    </View>
                  ) : (
                    <></>
                  ))}

                <Text style={styles.section} />
                {basePayment.paymentSourceJson && (
                  <View style={styles.row}>
                    <Text style={styles.label}>{paymentSourceLabel}</Text>
                    <Text style={styles.value}>{basePayment.paymentSource.accountNumberLast4}</Text>
                  </View>
                )}
                {basePayment.paymentSource.paymentSourceType ===
                  PaymentSourceTypes['Credit Card'] && (
                  <View style={styles.row}>
                    <Text style={styles.label}>Card Type:</Text>
                    <Text style={styles.value}>{basePayment.paymentSource.accountType}</Text>
                  </View>
                )}
                <View style={styles.row}>
                  <Text style={styles.label}>Auth Code:</Text>
                  <Text style={styles.value}>{authCode}</Text>
                </View>

                {basePayment.status !== PaymentStatuses.Succeeded && !hasAdjustments && (
                  <Text style={styles.status}>{basePayment.status}</Text>
                )}
                {hasAdjustments && <Text style={styles.status}>Returned</Text>}
              </View>
              <View>
                {serviceFee && (
                  <View style={styles.row}>
                    <Text style={styles.serviceFeeDisclaimer}>
                      Note: There will be two separate charges on your statement. One for the base
                      amount to
                      <Text style={styles.strong}>{` ${basePayment.organizationName} `}</Text>
                      and one to
                      <Text style={styles.strong}>{' Paystar '}</Text>
                      for the service fee.
                    </Text>
                  </View>
                )}
              </View>
              {businessUnitFeaturesPaymentReceiptBarcodes && (
                <View style={styles.barcode}>
                  <Barcode dataToEncode={basePayment.referenceNumber} />
                </View>
              )}
              <View>
                <Text style={styles.footer}>Thank you for your payment!</Text>
                <Text style={styles.subFooter}>Please retain for your records.</Text>
                <View style={styles.logo}>
                  <Image source={logoUrl} />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </AsyncStateContainer>
  );
};

const ChunkText: React.FC<{
  text: string;
  maxLength: number;
  style?: ReactPDF.Style | ReactPDF.Style[];
}> = (props) => {
  const {text, maxLength, style} = props;
  const parts = (text || '').replace(/([^a-z0-9]+)/g, '$1!BR!').split('!BR!');
  const chunks: string[] = [];

  let idx = 0;
  let chunk = '';
  while (idx < parts.length) {
    const next = chunk + parts[idx];
    if (next.length < maxLength) {
      chunk = next;
    } else {
      chunks.push(chunk);
      chunk = parts[idx];
    }
    idx++;
  }

  if (chunk) {
    chunks.push(chunk);
  }

  return (
    <Text style={style}>
      {chunks.map((x, i) => (
        <View key={`${x}-${i}`}>
          <Text>{x}</Text>
        </View>
      ))}
    </Text>
  );
};

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flex: 1,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 5,
  },
  content: {
    flex: 1,
    fontFamily: 'Helvetica',
    paddingTop: 15,
  },
  title: {
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Helvetica',
    paddingBottom: 3,
    paddingHorizontal: 10,
  },
  subTitle: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  status: {
    fontSize: 15,
    textAlign: 'center',
    paddingVertical: 5,
    marginVertical: 10,
    borderWidth: 2,
    borderStyle: 'dashed',
    textTransform: 'uppercase',
  },
  section: {
    fontFamily: 'Helvetica',
    fontWeight: 500,
    fontSize: 12,
    paddingBottom: 2,
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: 4,
    textAlign: 'justify',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 4,
    paddingVertical: 3,
    fontWeight: 'normal',
  },
  label: {
    fontSize: 10,
    width: '40%',
    color: '#000',
  },
  wideLabel: {
    fontSize: 10,
    width: '75%',
    color: '#000',
  },
  value: {
    fontSize: 10,
    flex: 1,
    textAlign: 'right',
  },
  paymentTotalRow: {
    paddingTop: 5,
    fontWeight: 'normal',
  },
  strong: {
    fontWeight: 500,
    color: '#000',
  },
  multiAccountTitle: {
    fontSize: 10,
    textAlign: 'center',
    padding: '0 10 10',
    color: '#000',
  },
  accountContainer: {
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    paddingTop: 5,
    paddingBottom: 5,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 4,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 'normal',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  footer: {
    fontFamily: 'Helvetica',
    fontWeight: 500,
    fontSize: 10,
    padding: '5 0 3',
    borderTopColor: '#000',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    textAlign: 'center',
  },
  subFooter: {
    fontSize: 8,
    textAlign: 'center',
  },
  serviceFeeDisclaimer: {
    fontSize: 10,
    textAlign: 'center',
    padding: '4 10',
    color: '#000',
  },
  logo: {
    padding: '15 25% 10',
    display: 'flex',
  },
  barcode: {
    padding: '15 15 15 15',
  },
});
