import {forEach, isNil} from 'lodash';
import {useEffect} from 'react';
import {RouteComponentProps} from '@reach/router';
import {ClientQueryKeys} from '../api/generated/enums';
import {userManager} from '../auth';

export const SignOut = (props: RouteComponentProps) => {
  useEffect(() => {
    signOut();
  }, []);

  return null;
};

interface SignOutProps {
  businessUnitSlug?: string;
  returnUrl?: string;
  paymentSuccessful?: boolean;
}

const AuthQueryKeyLookup = {
  businessUnitSlug: ClientQueryKeys.BusinessUnitSlug,
  returnUrl: ClientQueryKeys.CustomRedirectUrl,
  paymentSuccessful: ClientQueryKeys.PaymentSuccessful,
};

export const signOut = (signoutProps: SignOutProps = {}) => {
  const queryParams = {};

  forEach(signoutProps, (value, key) => {
    if (!isNil(value)) {
      queryParams[AuthQueryKeyLookup[key]] = value;
    }
  });

  userManager.signoutRedirect({
    extraQueryParams: queryParams,
  });
};
