import React from 'react';
import {Loader} from 'semantic-ui-react';
import {css} from '@emotion/react/macro';

import {DelayedLoadingContainer} from './delayed-loading-container';
import {Alert} from './alert';

type AsyncStateContainer = {
  loading: boolean;
  error?: Error;
  children: any;
  delayInMs?: number;
};

export const AsyncStateContainer: React.FC<AsyncStateContainer> = ({
  loading,
  error,
  children,
  delayInMs,
}) => {
  if (loading) {
    return (
      <DelayedLoadingContainer delayInMs={delayInMs}>
        <div
          css={css`
            margin-top: 20px;
          `}
        >
          <Loader inline="centered" active />
        </div>
      </DelayedLoadingContainer>
    );
  }

  if (error) {
    return <Alert negative>{error.message}</Alert>;
  }

  return children;
};
