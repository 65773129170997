/* eslint-disable */

import {
  FormConfigurationGetDto,
  SubmitFormRequest,
  DependentFormResponseDto,
  SubmitFormResponse,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class FormsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAllFormConfigurationsForCustomerDashboard(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      accountId?: number;
      /**  */
      displayLocation?: string;
      /**  */
      conditionJson?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<FormConfigurationGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/form-configurations/customer-dashboard';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AccountId: params['accountId'],
        DisplayLocation: params['displayLocation'],
        ConditionJson: params['conditionJson']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFormConfigurationById(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      formConfigurationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FormConfigurationGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/form-configurations/{formConfigurationId}';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{formConfigurationId}', params['formConfigurationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFormConfigurationByPaymentSessionIdentifier(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      paymentSessionIdentifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FormConfigurationGetDto>> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/business-units/{businessUnitId}/form-configurations/checkout/{paymentSessionIdentifier}';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{paymentSessionIdentifier}', params['paymentSessionIdentifier'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAnonymousFormConfigurationById(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      formConfigurationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FormConfigurationGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/form-configurations/{formConfigurationId}/public';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{formConfigurationId}', params['formConfigurationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitForm(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      formConfigurationId: number;
      /**  */
      body?: SubmitFormRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SubmitFormResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/form-configurations/{formConfigurationId}/submit';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{formConfigurationId}', params['formConfigurationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitAnonymousForm(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      formConfigurationId: number;
      /**  */
      body?: SubmitFormRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SubmitFormResponse>> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/business-units/{businessUnitId}/form-configurations/{formConfigurationId}/submit/public';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{formConfigurationId}', params['formConfigurationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
