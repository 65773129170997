import React from 'react';
import {CurrencyCodes} from '../api/generated/enums';

type Currency = {
  amount: number;
  fractionSize?: number;
  locale?: 'en-us';
  currency?: CurrencyCodes;
  currencySign?: 'standard' | 'accounting';
};

const Currency: React.FC<Currency> = (props) => <>{formatCurrency(props)}</>;

export const formatCurrency = ({
  amount,
  fractionSize = 2,
  locale = 'en-us',
  currency = 'USD',
  currencySign = 'standard',
}: Currency) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencySign,
  });
  return formatter.format(amount / 10 ** fractionSize);
};

export default Currency;
