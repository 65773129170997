import React, {createContext, useContext, useState} from 'react';
import produce from 'immer';

type FormState = {
  disabled: boolean;
};

const INITIAL_STATE = {
  state: {
    disabled: false,
  },
  setState: () => undefined,
};

type FormStateContext = {
  state: FormState;
  setState: (stateMutator) => void;
};

export const FormStateContext = createContext<FormStateContext>(INITIAL_STATE);

type FormStateProvider = {
  children: React.ReactNode;
  initialState?: FormState;
};

const FormStateProvider: React.FC<FormStateProvider> = (props) => {
  const initialState = props.initialState || INITIAL_STATE.state;
  const [state, _setState] = useState<FormState>(initialState);
  const setState = (stateMutator) => {
    _setState(
      produce(state, (draft) => {
        stateMutator(draft);
      })
    );
  };

  return (
    <FormStateContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {props.children}
    </FormStateContext.Provider>
  );
};

function useFormState(): FormStateContext {
  const formState = useContext(FormStateContext);
  if (!formState) {
    throw new Error(`useFormState must be used within a FormStateProvider`);
  }
  return formState;
}

export {FormStateProvider, useFormState};
