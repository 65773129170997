import React from 'react';
import {FieldRenderProps, useField} from 'react-final-form';
import {Form} from 'semantic-ui-react';
import {useFormState} from '../state-context';

type FieldCustomValue = {
  fieldName: string;
  fieldLabel?: string;
  render: (field: FieldRenderProps<any, HTMLElement>) => JSX.Element | false | undefined;
};

export const FieldCustomValue: React.FC<FieldCustomValue> = ({fieldName, fieldLabel, render}) => {
  const {state} = useFormState();
  var field = useField(fieldName);
  return (
    <div className="form-field">
      <Form.Field disabled={state.disabled}>
        {fieldLabel ? <label>{fieldLabel}</label> : <span className="no-label" />}
        {render(field)}
      </Form.Field>
    </div>
  );
};
