import mitt from 'mitt';
import {useEffect} from 'react';
import {HubConnectionState} from '@microsoft/signalr';
import {PaymentIntentDetailDto} from '../api/generated/index.defs';

let emitter = mitt();

type SubscriptionEventMap = {
  'payment-updated': {paymentId: number};
  'payment-detail-selected': {paymentId: number; referenceNumber?: string; isEmbedded?: boolean};
  'scheduled-payment-selected': {scheduledPaymentId: number};
  'business-unit-updated': {businessUnitId: number};
  'organization-updated': {organizationId: number};
  'payment-fields-updated': {businessUnitId: number};
  'session-expired': undefined;
  'account-updated': {accountId: number};
  'account-merged': {accountId: number};
  'internal-account-notes-updated': {accountId: number};
  'payment-session-updated': {paymentSession: PaymentIntentDetailDto};
  'payment-session-processed': {paymentIntent: PaymentIntentDetailDto};
  'editing-job-template': {jobTemplateId: number};
  'client-emailed': undefined;
  'creating-job-from-template': {jobTemplateId: number};
  'job-updated': {jobId: number};
  'job-execution': {jobId: number};
  'signalr-state-changed': HubConnectionState;
  'terms-of-service-updated': {termsOfServiceId: number};
  'admin-mock-role': {role: string};
  'account-payments-date-filter': {paymentId: number};
  __NEVER__: {};
};

export type SubscriptionEvents = keyof SubscriptionEventMap;

export function useSubscription<K extends SubscriptionEvents>(
  eventName: K,
  cb: (event: SubscriptionEventMap[K]) => void
): void {
  useEffect(() => {
    emitter.on(eventName as any, cb as any);
    return () => emitter.off(eventName as any, cb as any);
  });
}

export function notify<K extends SubscriptionEvents>(eventName: K, event: SubscriptionEventMap[K]) {
  if (eventName === '__NEVER__') {
    throw Error('Never call this event...');
  }
  emitter.emit(eventName, event);
}

export function subscribeGlobal<K extends SubscriptionEvents>(
  eventName: K,
  cb: (event: SubscriptionEventMap[K]) => void
): () => void {
  emitter.on(eventName as any, cb as any);
  return () => emitter.off(eventName as any, cb as any);
}
