import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import {Env} from '../config/env-vars';
import {logger} from '../utils/logger';
import {AnalyticsEvents} from './events';

type TrackedEventProperties = {
  subject: string;
  value?: number;
  [index: string]: any;
};

type Analytics = {
  identify: Mixpanel['identify'];
  trackEvent: (eventName: AnalyticsEvents, properties?: TrackedEventProperties) => void;
  setDefaultEventProperties: (properties?: TrackedEventProperties) => void;
  timeEvent: (eventName: AnalyticsEvents) => void;
  trackPage: (url: string) => void;
  trackUserProperties: (properties: {[index: string]: any}) => void;
};

const log = logger('analytics');

const {isProductionBuild, mixPanelToken, googleAnalyticsId} = Env;

const initializeMixpanel = isProductionBuild && !!mixPanelToken;
const initializeGoogleAnalytics = isProductionBuild && !!googleAnalyticsId;

let analytics: Analytics;

let identified = false;

let defaultEventProperties = {};

analytics = {
  identify: mockInDevelopment('identify', (uniqueId) => {
    identified = true;
    mixpanel.identify(uniqueId);
    ReactGA.set({userId: uniqueId});
  }),
  setDefaultEventProperties: mockInDevelopment(
    'setDefaultEventProperties',
    (properties: TrackedEventProperties) => {
      defaultEventProperties = properties;
    }
  ),
  trackEvent: mockInDevelopment('trackEvent', (eventName, properties: TrackedEventProperties) => {
    const mergedProperties = {...defaultEventProperties, ...properties};

    if (identified) {
      mixpanel.track(eventName, mergedProperties);
    }

    const [category, action] = eventName.split('.');

    ReactGA.event({
      category,
      action,
      label: mergedProperties?.subject,
      value: mergedProperties?.value,
    });
  }),
  timeEvent: mockInDevelopment('timeEvent', (eventName) => {
    if (identified) {
      mixpanel.time_event(eventName);
    }
  }),
  trackPage: mockInDevelopment('trackPage', (url) => {
    // ReactGA.pageview(url);

    if (identified) {
      const pageview: AnalyticsEvents = 'routes.page-viewed';
      mixpanel.track(pageview, {url});
    }
  }),
  trackUserProperties: mockInDevelopment('trackUserProperties', (properties) => {
    if (identified) {
      mixpanel.people.set(properties);
    }
  }),
};

if (initializeMixpanel) {
  mixpanel.init(mixPanelToken as string);
}

if (initializeGoogleAnalytics) {
  ReactGA.initialize(googleAnalyticsId as string);
}

function mockInDevelopment(method, action) {
  return isProductionBuild
    ? (...args) => {
        try {
          action(...args);
        } catch (error) {
          log.error(`analytics.${method}`, error);
        }
      }
    : (...args) => log.debug(`analytics.${method}`, ...args);
}

export {analytics};
