import {isEqual} from 'lodash';
import {useRef} from 'react';

export const useMemoizedValue = (value) => {
  const stateRef = useRef(value);
  if (!isEqual(value, stateRef.current)) {
    stateRef.current = value;
  }
  return stateRef.current;
};
